.baseInput:focus {
    outline: none;
    font-size: 13px;
    font-weight: normal;
    background-color: transparent;

}


.baseInput {
    outline: none;
    border: none;
    height: 95%;
    width: 75%;
}

::placeholder {
    color: #ABAAAA;
    font-size: 15px;
    font-weight: normal;

}

.containerIconInput {
    display: flex;
    justify-content: left;
    min-width: 100%;
    height: 45px;
    background-color: #fff;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    align-items: center;
    min-height: 30px;


}

.containerBigIconInput {
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 100px;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    min-height: 30px;

}

.containerIcon {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.icon {
    min-width: 20px;
    width: 30%;
    max-height: 30px;
    filter: invert(80%);
}

.icon-eye {
    border: none;
    height: 30px;
    background-color: #fff;
}

.iconInput {
    width: 75%;
}

.bigIconInput {
    width: 75%;
    height: 100%;
    background-color: royalblue;
}

.cotizacion-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cotizacion-title {
    font-size: 13px;
    color: #652D52;
    margin-bottom: 2%;
    font-weight: 600;
    /*min-width: max-content;*/
}

.cotizacion-input {
    padding-left: 10%;
    max-width: 90%;
    background-color: #F1F1F1;
    border-radius: 4px;
    outline: none;
    border: none;
    height: 3.5vh;
    font-size: 13px;
    color: #652D52;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cotizacion-input::placeholder {
    font-size: 13px;
    color: #652D52;
    align-self: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.vi-container {
    display: flex;
    align-items: center;
    margin: .5vh 0;
    width: 100%;
    justify-content: flex-end;
}

.vb-info-left .vi-container {
    justify-content: flex-start;
}

.vi-title {
    font-weight: 500;
    font-size: 12px;
    min-width: 100px;
    width: 100px;
    max-width: 100px;
}

.vb-info-left, .vb-info-right {
    max-width: 40%;
}

.right-content-text {
    width: fit-content;
    min-width: 100px;
    text-align-last: end;
    margin-right: 10px;
}

.vi-input {
    background-color: #EAE2E7;
    border-radius: 6px;
    color: #652D52;
    padding-left: 4%;
    /*width: 50%;*/
    font-size: 12px;
    margin-right: 5px;
}


.vi-input::placeholder {
    color: #652D52;
    font-size: 12px;
}

.vi-input:focus {
    background-color: #EAE2E7;
    border-radius: 6px;
    color: #652D52;
    padding-left: 2%;
    width: 100%;
    font-size: 12px;
}

.vi-input-container {
    height: 3vh;
    max-height: 35px;
    display: flex;
    max-width: 50%;
    /*justify-content: flex-end;*/
}

.vb-info-left .vi-input-container {
    width: calc(100% - 100px);
}

.vid-dropdown {
    background: transparent;
    color: white;
    border: none;
    font-size: 12px;
}

.vid-dropdown option {
    background: transparent;
    color: black;
    border: none;
    font-size: 12px;
}

.cotizacion-input-id-container {
    width: 100%;
    display: flex;
}

.cotizacion-id-type {
    max-width: 20%;
    background-color: #F1F1F1;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 13px;
    color: #652D52;
    display: flex;
    align-items: center;
    margin-right: 5px;
    padding: 0 5px;
}

.cotizacion-input-id {
    padding-left: 10%;
    max-width: 75%;
    background-color: #F1F1F1;
    border-radius: 4px;
    outline: none;
    border: none;
    height: 3.5vh;
    font-size: 13px;
    color: #652D52;
}

.cotizacion-input-id::placeholder {
    font-size: 13px;
    color: #652D52;
    align-self: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.vi-single-input {
    width: 100%;
}

.input-percent {
    width: 28px;
    max-width: 30px;
    min-width: 25px;
}

.iconInputPopupSearch {
    outline: none;
    border: none;
    height: 95%;
    width: 85%
}

.containerIconInputPopupSearch {
    width: 90%;
    margin: auto;
}

.psSearchPopup {
    width: 90%;
    margin: auto;
}

.psSearchInput {
    width: 85%;
    padding-left: 5%;
}

.spSearchIconContainer {
    width: 10%;
    border-radius: 0 13px 13px 0;
    background-color: #652D52;
}

.spSearchIcon {
    filter: invert(100%) !important;
    width: 80% !important;
}


.config-input-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.config-input-title {
    color: #652D52;
    font-size: 12px;
    margin-bottom: 5px;
}

.input-container-style {
    width: 96%;
    box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5px 2%;
}

.config-popup-input input {
    width: 96%;
    color: #652D52;
    font-size: 13px;
    padding: 5px 2%;
    outline: none;
    border: none;
    height: 95%;
}

.currency-format-div {
    display: flex;
    align-items: center;
}

.currency-format-div-double {
    min-width: 43%;
    display: flex;
    align-items: center;
}

.right-double-input {
    min-width: 90px;
}



.disabled {
    opacity: 0.5;
    pointer-events: none;
}

/* Estilo para el checkbox */
.checkbox-label input[type="checkbox"] {
    opacity: 0;
    pointer-events: none;
}



.checkbox-label {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

/* Estilo para el checkbox personalizado */
.checkbox-label .checkbox-box {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid orange;
    overflow: hidden;
    margin-right: 10px;
}

/* Estilo para el checkbox marcado */
/* Estilo para el checkmark (marca de verificación) */
.checkmark {
    position: relative;
    margin-right: 5px;
    border: 2px solid orange;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
}

.active-check {
    position: relative;
    background-color: white;
}

.active-check::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: orange;
    border-radius: 50%;
}

.check-icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.check-icon-container img {
    margin-top: 4px;
    height: 14px;
}

/* Estilo para el texto del checklist */
.admin-filter-input {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

/* Estilo para el checkbox deshabilitado */
.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.select-input {
    font-weight: 600;
}

.animate {
    animation: shake 0.5s ease-in-out;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.input-space {
    display: flex;
    flex-direction: column;
}

.optional-container {
    display: flex;
    align-items: center;

}

.input-check {
    display: flex;
    align-items: center;
}

.line-separator {
    margin: 0 5px;
}

.optional {
    font-size: 16px;
    font-weight: 500;
    color: #652D52;
}
.documentos-title{
    font-weight: bold;
    font-size: 18px;
    color: #3E1E2B;
    width: 90%;
    margin: 2vh auto ;
}

.goals-indicator-container{
    width: 90%;
    margin: 2vh auto;
    display: flex;
}

.goals-user-icon-contatiner{
    width: 20%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 5px;
}

.goals-user-icon{
    width: 80%;
    filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg) brightness(87%) contrast(88%);
}

.gi-right-container{
    width: 80%;
    display: flex;
    flex-direction: column;
}

.gi-title{
    font-weight: bold;
    font-size: 12px;
    color: #3E1E2B;
    margin-left: 5px;
}

.gi-number-container{
    width: 100%;
    display: flex;
}

.gi-number-icon-container{
    width: 20%;
    display: flex;
    filter: invert(66%) sepia(95%) saturate(2292%) hue-rotate(347deg) brightness(103%) contrast(98%);
}

.gi-number-icon{
    width: 80%;
}

.gi-number{
    font-size: 40px;
    color: #3E1E2B;
    align-self: center;
}

.ps-goals{
    overflow-y: scroll;
}


@media(min-width:768px){

    .service-provider .containerMenuButton{
        width: 23%;
    }

    .ps-container{
        height: calc(100% - 10vh);
        overflow-y: hidden;
    }

    .ps-goals{
        height: calc(100% - 10vh - 4vh);
        overflow-y: hidden;
        width: 50%;
        margin: 4vh auto 0 auto;
    }

    .ps-goals-container{
        height: 100px;
    }

    .goals-container{
        height: 45vh;
        overflow-y: scroll;
    }
}

@media(min-width:1200px){


    .ps-goals{
        width: 30%;
    }

    .gi-end-date, .goal-value{
        font-size: 12px;
    }

}

@media(min-width:1400px){

    .goals-container{
        height: 50vh;
        overflow-y: scroll;
    }   

}




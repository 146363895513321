.check-qr-code {
  display: grid;
  padding: 12px;
  height: 100dvh;
  box-sizing: border-box;
  align-content: baseline;
  gap: 24px;
  grid-template-rows: auto auto 1fr auto;

  &>header {
    display: flex;
    align-items: center;
    gap: 12px;

    &>div {
      display: flex;
      flex-direction: column;

      & .user-name {
        font-weight: bold;
      }

      & .user-role {
        color: gray;
      }
    }
  }

  & .qr-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-block: auto;

    &>p {
      text-align: center;
    }
  }

  & .nav-bar {
    align-self: self-end;
  }
}
.react-excel__Page__textContent {
  display: none;
}
.react-excel__Page__annotations{
  display: none;
}

.react-excel__Page__canvas{
  max-width: 100%;
  height: auto !important;
}
.excelPreview-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.excelPreview-container:hover{
  cursor: pointer;
}

.title-container{
  position: absolute;
  bottom: 10px;
  background-color: rgba(42, 0, 39, 0.5);
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  backdrop-filter: blur(1px);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.excelPreview{
  max-height: 250px;
}

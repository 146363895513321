.bitacle-chat-body {
  border: var(--border);
  flex: 1;
  border-radius: var(--border-radius);
  padding: var(--separation);
  background: url("../../../../../../assets/icons/png/bg-chat.png")
    color-mix(in srgb, transparent 90%, #652d52);
  scroll-behavior: smooth;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: normal;
  scrollbar-width: thin;
  margin-block: 8px;

  & article:hover {
    scale: none !important;
    box-shadow: none !important;
  }

  & .down-circle-icon {
    position: absolute;
    bottom: 80px;
    right: 5%;
    color: rgba(209, 172, 218, 0.847);
    font-size: 34px;
    cursor: pointer;
  }

  & ::-webkit-scrollbar {
    display: block;
  }

  & > :not(:first-child) {
    margin-top: 24px;
  }

  & .chat-message {
    max-width: 48ch;
    width: fit-content;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    position: relative;

    & .message-options-dropdown {
      position: absolute;
      right: 6px;
      cursor: pointer;
    }

    & .message-options-dropdown:hover {
      color: #fd941b;
    }

    & .message-wrapper {
      border: var(--border);
      border-radius: var(--border-radius);
      padding: 8px;
      font-size: 14px;
      box-sizing: border-box;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      width: fit-content;

      & .header-title {
        font-size: 15px;
        color: #652d52;
        margin-bottom: 12px;
        text-align: center;
      }
    }

    &:is([data-type="message"]) {
      & .message-paragraph {
        word-break: break-word;
        & .tagged-name {
          color: #652d52;
          font-weight: bold;
        }
      }
    }

    &:is([data-type="activity"]) {
      & .message-wrapper {
        & .activity-message-wrapper {
          display: flex;
          flex-direction: column;
          gap: 24px;

          & > p {
            text-wrap: pretty;
            word-break: break-word;
          }

          & .activity-resources-wrapper {
            display: flex;
            gap: 6px;
            flex-wrap: wrap;

            & .ant-image {
              & img {
                object-fit: cover;
              }
            }

            & > * {
              border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
              border-radius: 6px;
              overflow: hidden;
            }
          }
        }
      }
    }

    &:is([data-type="audio"]) {
      width: 100%;

      & .message-wrapper {
        width: 100%;

        & .audio-message-wrapper {
          border-radius: 6px;
          max-width: 100%;
          width: 100%;
        }
      }
    }

    &:is([data-type="file"]) {
      & .file-message-wrapper {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;

        & > * {
          border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
          border-radius: 6px;
          overflow: hidden;
        }
      }
    }

    & .chat-message-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2px;
      flex-wrap: wrap;
      gap: 12px;
      line-height: 1.1;

      & .footer-date {
        color: color-mix(in srgb, transparent 40%, black);
        font-size: 11px;
        text-transform: lowercase;
        letter-spacing: 0.5px;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      & .footer-details {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 13px;

        & .emisor {
          color: #652d52;
        }
      }
    }

    &:is(.is-other-user) {
      & .message-wrapper {
        border-bottom-left-radius: 0px;
        background-color: #fff;
      }

      & .options-msg-btn {
        right: -28px;
      }
    }

    &:not(:is(.is-other-user)) {
      align-self: flex-end;

      & .message-wrapper {
        border-bottom-right-radius: 0px;
        align-self: flex-end;
        background-color: color-mix(in srgb, #fff 80%, #fd941b);
      }

      & .options-msg-btn {
        left: -28px;
      }
    }

    &:is(.prev-same-user) {
      margin-top: 2px;
    }

    &:is(.next-same-user) {
      & .message-wrapper {
        border-radius: var(--border-radius);
      }
    }

    &:is(.prev-msg-late) {
      margin-top: 24px;
    }

    & .options-msg-btn {
      border-radius: 50%;
      position: absolute;
      top: 0px;
      border: 1px solid color-mix(in srgb, transparent 80%, #652d52);

      &:hover {
        background-color: #fff;
      }

      & svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.options-msg-popover {
  --bg-color: color-mix(in srgb, transparent 60%, gray);

  & .ant-popover-content {
    & .ant-popover-inner {
      padding: 0px;
      overflow: hidden;

      & .popover-msg-list {
        margin: 0px;
        padding: 4px;
        display: flex;
        flex-direction: column;
        background-color: var(--bg-color);
        color: #652d52;
        gap: 4px;

        & .ant-btn {
          font-size: 12px;
          width: 100%;

          &:hover {
            background-color: color-mix(in srgb, transparent 40%, #fff);
          }
        }
      }
    }
  }

  & .ant-popover-arrow {
    &::before {
      background-color: var(--bg-color);
    }
  }
}

.visible-date {
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px auto;
  text-align: center;
  font-size: 12px;
  color: #555;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: fit-content;
  z-index: 1;
}
.react-calendar button:focus{
    outline: none;
}

.schedule-calendar .react-calendar__navigation__arrow{
    font-size: 22px;
}

.react-calendar button{
    background-color: transparent;
    border: none;
    width: 20%;
    height: 20%;
}

.react-calendar__month-view{
    height: 100%;
}

.schedule-calendar .react-calendar__month-view__days__day--neighboringMonth abbr, .disable-day abbr{
    color: #ABAAAA !important;
}

.react-calendar__navigation__label{
    text-transform: capitalize;
}

.schedule-calendar .react-calendar {
        width: 90%;
        height: 90% ;
        border: none ;
        margin: auto ;
}

.schedule-calendar .react-calendar__navigation{
    height: 20% ;
    display: flex;
    align-items: center;
}

.schedule-calendar .react-calendar__viewContainer{
    height: 80%;
}

.schedule-calendar .react-calendar__navigation button{
    min-width: 7px ;
    color: #3E1E2B;
    font-weight: 600;
    background-color: transparent;
    border: none;
    padding: 10px;
    width: fit-content;
}


.schedule-calendar .react-calendar__month-view__weekdays{
    color: #3E1E2B;
    font-weight: 600;
    height: 2vh;
    font-size: 12px;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 2vh;
}

.schedule-calendar .react-calendar abbr{
    color: #3E1E2B;
    font-size: 12px;
    text-decoration: none;

}

.schedule-calendar .react-calendar__month-view__weekdays__weekday{
    width:14% !important;
    text-align: center !important;
    
}

.schedule-calendar .react-calendar__month-view__days{
    display: flex;
    width: 100%;
    justify-content: center;  
}

.schedule-calendar .dayServiceSchedule{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20%;
}

.schedule-calendar .serviceDot{
    background-color: #FD941B;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-top: 15%;
    margin-left: 2px;
    
}

.schedule-calendar .react-calendar__tile, .react-calendar__month-view__days__day, .react-calendar__month-view__days__day--weekend{
    justify-content: center;
}

.schedule-calendar .react-calendar__navigation button {
    font-weight: 300;
    font-size: 30px;
}

.schedule-calendar .react-calendar__navigation .react-calendar__navigation__label{
    font-weight: 600;
    font-size: 18px;
}

/*
.schedule-calendar .react-calendar__tile--active {
    border-radius: 50%;
    background-color: #fd941b;
}
*/
.schedule-calendar .react-calendar__tile, .react-calendar__month-view__days__day, .react-calendar__month-view__days__day--weekend {
    border-radius: 50%;
    height: 37.02px;
    width:  37.02px;
    flex-basis: 100%;
}

.schedule-calendar .react-calendar__tile--active abbr {
    font-weight: bold;
    background: #fd941b;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.serviceDot-container{
    display: flex;
    width: 100%;
    justify-content: center;
}

.serviceDot{
    background-color: #FD941B;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-top: 15%;
    margin-left: 2px;
    
}


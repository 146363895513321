.header-screen {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #FF8F1C;


  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: #652D52;
  }
}

.go-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  box-shadow: none;

  &>svg {
    height: 18px;
    width: 18px;
    stroke: 2;
  }
}
.cotizacion-textarea-container{
    width: 90%;
    margin: auto;
}

.cotizacion-space-fields{
    margin: 0 auto 2vh auto !important;
    width: 90%;
}

.area-fields-container{
    margin: 2vh 0;
}

.ab-style{
    width: 100%;
}

.new-item-style{
    width: 100%;
    background-color: #652D52;
}

.cotizacion-cost-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #3E1E2B;
}

.cotizacion-cost-title{   
    font-size: 16px;      
    margin-bottom: 10px;
}

.cotizacion-cost-price{
    font-weight: bold;
    font-size: 20px;
}

.new-item{
    font-weight: bold;
    font-size: 14px;
    color: #FD941B;
    margin: 5vh 0;
    text-align: center;
}

.add-material-container{
    width: 90%;
    margin: 4vh auto;
    display: flex;
    font-size: 14px;
    color: #FD941B;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}

.remove-material-container{
    width: 90%;
    margin: 0 auto 4vh auto;
    display: flex;
    font-size: 14px;
    color: #FD941B;
    align-items: center;
    font-weight: 600;
}

.row-container{
    display: flex;
    width: 100%;
}

.table-left-side{
    background-color: #652D52;
    color: white;
    width: 50%;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh 0;
}

.table-right-side{
    width: 50%;
    padding: 1vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.materiales-tabla-container{
    background: #FFFFFF;
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    width: 90%;
    margin: 0 auto 2vh auto;
}

.top-left-radius{
    border-top-left-radius: 13px;
}

.bottom-left-radius{
    border-bottom-left-radius: 13px;
}

.table-input-container{
    width: 80%;
    height: 80%;
}

.table-icon-color{
    background-color: #F1F1F1;
}

.descripcion-solucion-container{
    display: flex;
    margin: 2vh auto 0 auto;
    width: 90%;
}

.solucion-input{
    width: 80%;
}

.solucion-add-button-container{
    width: 20%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.solucion-add-button{
    filter: invert(69%) sepia(14%) saturate(6901%) hue-rotate(346deg) brightness(101%) contrast(98%);
    cursor: pointer;
}

.area-entry-container{
    display: flex;
    width: 90%;
    margin: 0 auto 2vh auto;
    justify-content: space-between;
}

.observaciones-container{
    margin-bottom: 4vh;
}

.cotizacion-form-container{
    /*height: calc(100% + 17vh);*/
    height: calc(100% - 20vh);
    overflow-y: scroll;
}

.cotizacion-item-container{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 2vh auto 0 auto;
}

.cotizacion-item-name{
    font-weight: bold;
    font-size: 18px;
    color: #FD941B;
}

.cotizacion-item-cancel{
    font-size: 14px;
    color: #FD941B;
}

.item-template-container{
    box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    display: flex;
    width: 90%;
    margin: 2vh auto;
    padding: 2vh 0;
    justify-content: space-between;
}

.it-big-icons-container{
    display: flex;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 4%;
}

.it-name-container{
    /*width: 30%;*/
    font-weight: bold;
    font-size: 14px;
    color: #FD941B;
    display: flex;
    align-items: center;
    margin-left: 4%;
}

.it-price-container{
    /*width: 48%;*/
    font-weight: bold;
    font-size: 16px;
    color: #652D52;
    display: flex;
    align-items: center;
    justify-content: center;
}

.it-icon{
    width: 90%;
    filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg) brightness(87%) contrast(88%);
}

.item-list-container{
    margin-bottom: 6vh;
}

.items-total-cost{
    margin: 4vh 0 ;
}

.time-entry-text{
    font-weight: 600;
    font-size: 12px;
    color: #4A2433;
    margin-bottom: 1vh;
}

.new-time-entry{
    width: 45%;
    display: flex;
    flex-direction: column;
}

.ai-icon-container{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 1vw;
}

.ai-icon{
    filter: invert(69%) sepia(14%) saturate(6901%) hue-rotate(346deg) brightness(101%) contrast(98%);
    width: 70%;
}

.it-icon-container{
    cursor: pointer;
}

.currency-format input{
    max-width: 100%;
}

.cotizacion-float-button-container .seguimientoButton, .cotizacion-float-button-container .seguimiento-button-portal-show{
    bottom: 10px;
    right: 10px;
    top: auto;
    position: fixed;
}

.cotizacion-float-button-container .container-values-seguimiento{
    bottom: 65px;
}



@media (min-width:768px){
    .cotizacion-form-container{
        height: calc(100% - 17vh);
        overflow-y: hidden;
    }

    .ps-services-main-container .new-entry-content{
        height: 100%;
    }

    .new-service-cotizacion-container{
        height: calc(100% - 15vh);
        overflow-y: scroll;
    }

    .cotizacion-float-button-container .seguimientoButton, .cotizacion-float-button-container .seguimiento-button-portal-show{
        bottom: 70px;
        right: 15%;
    }

    .cotizacion-float-button-container .container-values-seguimiento{
        bottom: 125px;
        right: 22%;
    }
}

@media (min-width:998px){

    .new-service-cotizacion-container{
        height: calc(100% - 13vh);
        overflow-y: scroll;
    }

    .cotizacion-float-button-container .seguimientoButton, .cotizacion-float-button-container .seguimiento-button-portal-show{
        bottom: 70px;
        right: 17%;
    }
}

@media (min-width:1200px){

    .cotizacion-float-button-container .seguimientoButton, .cotizacion-float-button-container .seguimiento-button-portal-show{
        bottom: 70px;
        right: 22%;
    }

    .cotizacion-float-button-container .container-values-seguimiento{
        bottom: 125px;
        right: 27%;
    }
}


@media (min-width:1400px){
    .cotizacion-form-container{
        height: calc(100% - 15vh);
    }

    .ps-services-main-container .new-entry-content{
        height: 100%;
    }

    .new-service-cotizacion-container{
        height: calc(100% - 10vh);
        overflow-y: scroll;
    }

    .cotizacion-float-button-container .seguimientoButton, .cotizacion-float-button-container .seguimiento-button-portal-show{
        bottom: 70px;
        right: 28%;
    }

    .cotizacion-float-button-container .container-values-seguimiento{
        bottom: 125px;
        right: 31%;
    }
}

.bitacle-chat-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--separation);

  & .chat-form {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;

    &>* {
      margin: 0px;
    }

    & .ant-form-item {
      height: 100%;
      width: 100%;

      & .ant-row {
        height: 100%;

        & .ant-col {
          & .ant-form-item-control-input {
            height: 100%;

            & .ant-form-item-control-input-content {
              height: 100%;

              & .message-input {
                height: 100%;

                & .ant-mentions {
                  height: 100%;

                  &>#message {
                    box-shadow: none;
                    resize: none;
                    field-sizing: content;
                    text-wrap: pretty;
                    max-height: 120px;
                    scroll-behavior: smooth;
                    box-sizing: border-box;
                    height: 100%;
                    line-height: normal;
                    font-size: 16px;
                    padding: 7px 11px;
                    width: 100%;
                    word-break: break-word;

                    &:is(:focus, :hover) {
                      border-color: color-mix(in srgb, transparent 45%, #652d52);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  & .ant-btn {
    --icon-size: 28px;
    width: auto;
    height: auto;
    padding: calc(var(--icon-size) / 4);
    box-shadow: none;
    border-radius: 50%;
    align-self: flex-end;

    & .ant-btn-icon {
      display: flex;

      &>svg {
        color: #fff;
        filter: none;
        height: var(--icon-size);
        width: var(--icon-size);
      }
    }
  }

  & .send-btn {
    background-color: #fd941b !important;
  }

  & .upload-btn {
    background-color: color-mix(in srgb, transparent 60%, #652d52) !important;
  }
}
.float-button {
  position: fixed;
  border-radius: 100%;
  bottom: 100px;
  z-index: 2;
  right: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.grid-docs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  padding: 10px;
}

.doc {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  & h4 {
    text-wrap: wrap;
    width: 80%;
  }
}

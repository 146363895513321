/* Modal.css */
.profile-photo-modal {
  width: 100%;
  height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  height: 100%;
}

.modal-content {
  position: relative;
  background-color: #652d5235;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  height: 100% !important;
  align-items: center;
}

.modal-content-profile {
  position: relative;
  background-color: #ffffff35;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.modal-content-profile img {
  height: 350px;
  width: 350px;
}

.close-btn-profile {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: white;
  font-size: 30px;
  background-color: #652d5250;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para pantallas grandes */
@media screen and (min-width: 1000px) {
  .modal-image {
    max-width: 900px;
    max-height: 50vh;
    object-fit: cover;
  }

  .image-resource {
    width: 1200px;
    height: 80vh;
    object-fit: contain;
  }

  .image-download {
    width: auto;
    height: 40px;
  }
}

.modal-profile-image {
  display: flex;
  width: 100% !important;
  height: 60%;
  justify-content: center !important;
}



/* Estilos para pantallas medianas y pequeñas */
@media screen and (max-width: 800px) {
  .modal-image {
    max-width: 400px;
    max-height: 600px;
  }

  .modal-profile-image img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }

  .image-resource {
    width: 350px;
    height: auto;
  }

  .image-download {
    width: auto;
    height: 35px;
  }
}


.button-download {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #652d525e;
  backdrop-filter: blur(10px);
  border: none;
  cursor: pointer;
  outline: none;

}
.emojiPicker-icons {
  height: 100%;
}

.emojiPicker-icons:hover {
  background-color: rgb(from yellow 22 22 22);
}

.emoji-container {
  width: 98.4%;
  height: 250px;
  margin: 4px;
  display: flex;
  z-index: 20;
}

.emoji-container aside {
  height: 100% !important;
}


  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
  }

.pdf-image {
  min-width:  60px !important;
  min-height:  100px !important;
  max-width: 100% !important;
  max-height:  50% !important;
}

  .imgAnimatePdf-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    z-index: 2; 
  }
  
  .imgAnimatePdf {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: heartbeat 3s infinite;
  }

  .imgAnimateExcel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: auto; 
    animation: heartbeat 3s infinite;
  }

  .spinner {
    width: 30px; 
    height: 30px; 
    border: 5px solid #333;
    border-top: 5px solid #fff; 
    border-radius: 50%; 
    animation: spin 3s infinite; 
  }

  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
  }
.dropdown-more {
  text-align: center;

  & .ant-dropdown-menu {
    & .ant-dropdown-menu-item {
      padding: 0px;

      & .ant-dropdown-menu-title-content {
        &>* {
          width: 100%;
        }
      }
    }
  }
}

.bitacle-header {
  display: flex;
  gap: 12px;
  align-items: center;

  & .header-info {
    --title-color: #652d52;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
    justify-content: space-between;

    & .info-title-phase {
      font-size: 24px;
      text-transform: lowercase;
      text-align: center;
      font-weight: bold;
      letter-spacing: 1px;
      color: color-mix(in srgb, #652d52 10%, var(--title-color));
      -webkit-text-stroke: 0.5px color-mix(in srgb, var(--title-color) 60%, #652d52);
      border: var(--border);
      padding: var(--separation);
      border-radius: var(--border-radius);
      width: 100%;

      &::first-letter {
        text-transform: uppercase;
      }

      &:is([data-phase="design"]) {
        --title-color: #f8bbd0;
      }

      &:is([data-phase="planeation"]) {
        --title-color: #d1c4e9;
      }

      &:is([data-phase="operation"]) {
        --title-color: #cfd8dc;
      }
    }

    & .header-btn-more {
      border: var(--border);
      padding: var(--separation);
      height: 100%;
      width: fit-content;
    }
  }
}
.phase-resume-contianer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.phase-resume-header {
  height: 12%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background-color: white;
}

.phase-resume-header h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #652D52;
  margin: 0;
  margin-right: 4px;
}


.resume-details-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .title-time-line{
    font-size: 14px;
  }
.togle-activity-title h3{
  font-size: 16px;
}
.togle-activity-title h3{
  min-width: 150px;
}
.timeline{
  overflow-x: hidden;
  margin-left: 4%;
}

.header-time-line-content{
  margin-left: 2%;
}
.time-line-header-content {
    min-width: 250px;
    max-width: 300px;
}
}

.days-resume {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 8%;
  background-color: white;
  border-radius: 8px;
  margin-top: 2%;
}

.rest-days {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.days-retraso {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rest-days h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #652D52;
  margin: 0;
  margin-right: 4px;
}

.rest-days p {
  font-size: 1rem;
  font-weight: 600;
  color: green;
  margin: 0;
  margin-right: 4px;
}

.days-retraso h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #652D52;
  margin: 0;
  margin-right: 4px;
}

.days-retraso p {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(248, 57, 57);
  margin: 0;
  margin-right: 4px;
}

.general-progress {
  height: 68%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: white;
  border-radius: 8px;
  margin-right: 1%;
  margin-top: 2%;
}

.dates-resume {
  height: 18%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  margin-right: 1%;
  margin-top: 2%;
}

.principalDate {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
}

.secondaryDate {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
}

.dates-resume h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #652D52;
  margin: 0;
  margin-right: 4px;
}

.date {
  font-size: 0.8rem;
  font-weight: 400;
  color: gray;
  margin: 0;
  margin-right: 4px;
}

.resume-actual-phase {
  border-radius: 8px;
  margin-top: 1%;
  margin-bottom: 0.4%;
  position: relative;
  overflow: hidden;
}

.general-resume-container {
  width: calc(50% - 6px);
  height: 100%;

}

.title-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.callback-actions {
  height: 12%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.progress-general {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.progress-general h3 {
  font-size: 18px;
  font-weight: 600;
  color: #652D52;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px
}

.progress-general h2 {
  font-size: 16px;
  font-weight: 600;
  color: #652D52;
  margin: 0;
  margin-top: 4px;
  height: 10px;
}

.callback-actions button {
  background-color: #652D52;
  color: white;
  border: none;
  height: 50%;
  width: 20%;
  margin: 8px;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.progress-bar {
  width: 80%;
  height: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: orange;
  transition: width 0.3s ease-in-out;
}

.progress-bar-remaining {
  height: 100%;
  background-color: #ccc;
}

.progress-phase {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: baseline;
}

.progress-phase h3 {
  display: flex;
  margin-left: 20px;
  width: 40%;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
}

.phase-progress {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phase-progress h2 {
  font-size: 16px;
  font-weight: 600;
  color: #652D52;
  margin: 0;
  margin-top: 4px;
  height: 10px;
}

.title-content p {
  font-size: 1rem;
  font-weight: 300;
  color: #652D52;
  margin: 0;
  margin-right: 4px;
}

.profile-image-user {
  margin-right: 4px;
}

.icon-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  filter: invert(17%) sepia(48%) saturate(1189%) hue-rotate(274deg) brightness(93%) contrast(84%);
  margin-right: 4px;
}

.icon-header-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  filter: invert(17%) sepia(48%) saturate(1189%) hue-rotate(274deg) brightness(93%) contrast(84%);
  margin-right: 4px;
}

.loader-container {
  width: 100%;
  height: 100%;
}
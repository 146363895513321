.map-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;

  .nav-bar {
    margin-inline: 20px;
    width: auto;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 99999px;
  border: 2px solid #fff;
}

.route-marker {
  width: 25px;
  height: 25px;
  background-color: #652d52;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.popover {
  width: 200px;
}

.route-info-container {
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    margin-top: 10px;
    background-color: #652d52;
    color: #fff;
    border-radius: 14px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 70%;

    span {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    h1,
    p {
      margin: 0;
    }

    p {
      font-weight: 500;
      font-size: large;
    }

    button {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      background-color: #fff;
      color: #652d52;
      border: none;
      border-radius: 8px;
      padding: 10px;
      display: flex;
      justify-content: center;
      gap: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .button-background {
      position: relative;
      width: 90%;
      height: 30px;
      margin-left: 10px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.3);
    }

    .slider {
      transition: width 0.3s, border-radius 0.3s, height 0.3s;
      position: absolute;
      left: 0;
      background-color: white;
      width: 40px;
      height: 40px;
      border-radius: 999999px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0;
    }

    .in-route {
      width: 96%;
      transform: translateX(-250px);
    }

    .material-icons {
      color: #652d52;
      font-size: 15px;
      user-select: none;
      display: flex;
      gap: 6px;
    }

    .slide-text {
      color: #fff;
      font-size: 13px;
      text-align: center;
      margin: 0;
      background-color: rgba(255, 255, 255, 0);
      text-transform: uppercase;
      user-select: none;
      transition: opacity 0.3s;
    }
  }
}

.contain-steps {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .first-contain-step {
    background-color: #652d52;
    padding: 12px;
    width: 300px;
    border-radius: 12px;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-size: 12px;
    }

    span {
      display: flex;
      gap: 20px;

      p {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .next-step {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: -100px;
    width: 200px;
    background-color: #fff;
    color: #652d52;
    border-radius: 8px;
    padding: 10px;
    font-weight: 600;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    p {
      font-size: 14px;
    }
  }
}

.container-timeline-route {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  height: 90dvh;
  box-sizing: border-box;

  & .timeline-container {
    height: 70dvh;
    padding-top: 20px;
    padding-inline: 20px;
    overflow-y: auto;
  }

  & h1 {
    font-size: 22px;
    margin: 20px;
    margin-bottom: 0;
    color: #652d52;
  }

  & .index-timeline {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #652d52;
    width: 25px;
    height: 25px;
    border-radius: 99999px;
    color: #fff;
    font-weight: bold;
  }

  & .timeline-item {
    padding-inline: 4px;
    border-radius: 6px;
    padding: 10px;
    width: 80dvw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    & p:first-child {
      font-size: 18px;
      color: #652d52;
      font-weight: 600;
    }

    & p:nth-child(2) {
      font-size: 11px;
      color: #652d52;
      margin: 0;
    }

    & p:nth-child(3) {
      font-size: 13px;
      margin: 0;
    }

    & .timeline-sub-item {
      margin-top: 10px;

      & p:first-child {
        font-size: 14px;
        margin: 0;
      }

      & p {
        font-size: 12px;
        margin: 0;
      }
    }
  }

  & .button-go-map {
    width: 200px;
    border: solid 1px #652d52;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    color: #652d52;
    align-self: center;
    margin-bottom: 36px;
  }

  .nav-bar {
    margin-inline: 20px;
    width: auto;
  }
}

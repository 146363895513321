.menu-desktop-container {
    display: none;
}

.noti {
    position: relative;
}

.right-options {
    display: flex;
    min-width: max-content;
    align-items: center;

}

.profile-photo-menu {
    border-radius: 10%;
    height: 30px;
    width: 30px;
    margin: auto 5px auto 4vw;
    cursor: pointer;
}

.menu-user-name {
    max-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.menu-user-name:hover {
    opacity: 50%;
}

.menu-selected {
    position: relative;
    color: #fd941b;
}

.profile-icon-menu {
    filter: invert(100%);
    cursor: pointer;
}

.menu-desktop-main-container .seguimientoButton {
    visibility: hidden;
}

@media (min-width:768px) {

    .menu-desktop-container {
        width: 100%;
        background-color: #652D52;
        color: white;
        height: 10vh;
        border-radius: 0 0 30px 30px / 0 0 25px 25px;
        display: flex;
        align-items: center;
        position: relative;

    }

    .menu-desktop-main-container .moreContainer {
        top: 70%;
        position: fixed;
        right: 6%;
        z-index: 3;
    }

    .menu-option {
        position: relative;
        margin-right: 4vw;
        cursor: pointer;
    }

    .menu-option:hover {
        opacity: 50%;
    }

    .logo-menu {
        max-width: 90px;
        margin-left: 4vw;
        margin-right: 6vw;
        animation: logo-menu 3s infinite alternate;
    }

    @keyframes logo-menu {
        0% {
            transform: scale(0.70);
        }

        50% {
            transform: scale(0.72);
        }

        100% {
            transform: scale(0.99);
        }
    }

    .center-options {
        width: -webkit-fill-available;
        display: flex;
    }

    .notifications-icon-menu {
        cursor: pointer;
        filter: invert(100%);
        padding-right: 4vw;
        border-right: 2px solid black;
    }

    .profile-photo-menu {
        margin: auto 5px auto 4vw;
    }

    .menu-desktop-main-container .seguimientoButton {
        visibility: visible;
    }

}

@media (min-width: 992px) {
    .logo-menu {
        max-width: 110px;
    }
}

@media(min-width:1200px) {

    .logo-menu {
        margin-left: 3vw;
    }

    .profile-photo-menu {
        margin: auto 5px auto 3vw;
    }

    .notifications-icon-menu {
        padding-right: 3vw;
        border-right: 2px solid black;
    }

    .menu-desktop-main-container .moreContainer {
        right: 6%;
        top: 80%;
    }

}

@media(min-width:1400px) {

    .menu-desktop-main-container .moreContainer {
        right: 6%;
        top: 70%;
    }

}

.pulse-in-new-message {
    position: absolute;
    top: -4px;
    right: 15px;
    height: 6px;
    width: 6px;
    background-color: #FD941B;
    /* Color de fondo del círculo */
    border-radius: 50%;
    animation: pulse 1s ease-out infinite;
    /* Animación */
}

.pulse-in-new-message::before {
    z-index: 8;
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    left: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #FD941B;
    /* Color del borde del círculo */
    opacity: 0;
    animation: pulse-border 1s ease-out infinite;
    /* Animación del borde */
}


@keyframes pulse-border {
    0% {
        opacity: 0;
        /* Borde invisible */
    }

    50% {
        opacity: 1;
        /* Borde visible */
    }

    100% {
        opacity: 0;
        /* Borde invisible */
    }
}


.pulse-is-new {
    position: absolute;
    top: -4px;
    right: -4px;
    height: 6px;
    width: 6px;
    background-color: #66C600;
    /* Color de fondo del círculo */
    border-radius: 50%;
    animation: pulse 1s ease-out infinite;
    /* Animación */
}

.pulse-is-new::before {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    left: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #66C600;
    /* Color del borde del círculo */
    opacity: 0;
    animation: pulse-border 1s ease-out infinite;
    /* Animación del borde */
}

@keyframes pulse-border {
    0% {
        transform: scale(0.95);
        /* Escala inicial del círculo */
    }

    50% {
        transform: scale(1.5);
        /* Escala media del círculo */
    }

    100% {
        transform: scale(1.95);
        /* Escala final del círculo */
    }
}

@keyframes pulse-border {
    0% {
        opacity: 0;
        /* Borde invisible */
    }

    50% {
        opacity: 0.5;
        /* Borde visible */
    }

    100% {
        opacity: 0;
        /* Borde invisible */
    }
}
.switch-input-andt {
  display: flex;
  justify-items: center;
  width: 100%;

  label {
    margin-right: 10px;
    color: #fd941b;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.input-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #fd941b;
  font-size: 18px;
}

.input-form input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #652d524c;
  border-radius: 8px;
  font-size: 16px;
}

.input-form input:focus {
  outline: none;
}

.images-container {
  & .ant-row {
    display: flex;
    justify-content: left;
    gap: 12px;
  }

  .mediaContent {
    height: 120px;
    width: 120px;
    object-fit: contain;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #d9d9d984;
  }
}

.register-info-container{
    width: 100%;
    /*height: 80vh;*/
    /* position:absolute; */
    /*margin-bottom: 15%;*/
    height: calc(100% + 50px);
}

.register-type-container{
    height:55vh;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-type{
    margin: 2vh 0 2vh 0;
}

.grid-fields-container{
    width: 100%;
    margin: 0 auto 2vh auto;
    line-height: 0.5;
    display: grid;
     grid-template-columns: 39% 59%;
}

.ps-options-usertype {
    display: grid;
    width: 90%;
    margin: auto;
    grid-template-columns: auto auto auto;
    grid-row-gap: 1.5vh;
    align-items: flex-start;
  }

.options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 12.5vh;
  }

.basic-input{
    width: 75%;
    height: 80%;
}

.fc-text{
    font-size: 18px;
    width: 100%;
    margin: 4vh auto 4vh auto;
    color: #4A2433;
    font-weight: bold;
    line-height: 1;
}

.text-usertype-small{
    width: 90%;
    margin: 2vh auto 0 auto;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    color: #4A2433;
}

.register-botton-menu{
    width: 90%;
    margin: 2vh auto 2vh auto;
    text-align: center;
}

.ps-big-car-size{
    /*width: 90%;*/
    width: 75px;
    height: 75px;
    min-width: 70px;
    color: #4A2433;
    min-height: 75px;
}

.transport-type-container{
    height: 90px;
    margin-bottom: 15px;
}

.container-speciality{
    /*height: calc(100% + 17vh);*/
    height: 100vh;
    overflow-y: scroll;
    margin-bottom: 2vh;
}

@media (min-height:768px){
    .container-speciality .ps-options-menu-container{
        grid-template-columns: auto auto auto auto;
        width: 80%;
        margin: 6vh auto 0 auto;
        padding: 0 ;
    }

    .container-speciality{
        height: fit-content;
        overflow-y: hidden;
        margin-bottom: 0;
    }
}

@media (min-width: 1200px){
    .container-speciality .ps-options-menu-container{
      width: 70%;
    }
    
  }

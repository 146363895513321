.appbar{
    width:100%;
    background-color: #652D52;
    color:white;
    height: 17vh;
    border-radius: 0 0 30px 30px / 0 0 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.small-text-container{
    width: 90%;
    font-size: 13px;
}

.big-text-container{
    width: 90%;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 3vh;
}

.appbar-profile{
    width:100%;
    background-color: #652D52;
    color:white;
    height: 47vh;
    border-radius: 0 0 30px 30px / 0 0 25px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.profile-header-container{
    display: flex;
    width:90%;
    margin: 0 auto 0 auto;
    align-items: center;
    justify-content: space-between;
    
}

.profile-header{
    font-size: 25px;
    margin-top: 2vh;
    margin-left: 8.5%;
    text-align: center;
    width:90%;
}

.profile-config-icon{    
    margin-top: 2vh;
    width:10%
}

.config-icon{
    filter: invert(100%);
    width:20px
}

.profile-user-info{
    margin-top: 2vh;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.profile-photo{
    height: 65px;
    width: 65px;
    margin-bottom: 1.5vh;
}

.profile-name{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.profile-rol{
    font-size: 13px;
    text-align: center;
}

.profile-user-data{
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
}

.appbar-logo-container{
    display: none;
}

/*SMALL DEVICES*/
@media (max-width: 576px) {
    .appbar-profile{
        height: 41vh;   
    }
    
    .data-info{
        font-size: 22px;
    }

    .addRounded {
        min-width: 60px;
        height: 60px;
        width: 60px;
    }

    .profile-photo{
        height: 60px;
        width: 60px;
        margin-bottom: 1vh;
    }

    .profile-user-info {
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .profile-header {
        font-size: 23px;
        margin-top: 1vh;
    }
}

@media(min-width: 768px){
    .big-text-container{
        margin-bottom: 0;
    }
}


.headerSection{
    width: 90%;
    height: 40%;
    display: flex ;
    justify-content: space-between;
    align-items: center;
}

.header-togle{
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order-debris-removal {
  height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 24px;
  padding: 12px;
  box-sizing: border-box;

  & .debris-removal-wrapper {
    display: flex;
    flex-direction: column;

    &>h1 {
      font-size: 24px;
      margin: 0px;
    }

    & .debris-removal-form {
      height: 100%;
      display: flex;
      flex-direction: column;

      & .debris-input-wrapper {
        margin-block: 12px;

        & .date-picker {
          width: 100%;
        }
      }

      & .order-submit-btn {
        margin-block: auto 0px;
        box-shadow: none;
      }
    }
  }
}
.ps-notifications-container {
  width: 90%;
  margin: 0 auto 2vh auto;
}

.today-notifications {
  border-bottom: 1px solid rgba(193, 171, 186, 0.29);
}

.ps-container {
  /*height: 80vh;*/
  height: 100%;
  overflow-y: scroll;
}

.service-visit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10vh;
  justify-content: center;
  margin: 2vh 0;
}

.service-visit-title {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3e1e2b;
  margin-bottom: 10px;
}

.service-visit-price {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3e1e2b;
}

#img-text-service-details {
  width: 80%;
  filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg) brightness(87%) contrast(88%);
}

.re-info-container {
  width: 90%;
  margin: 0 auto 7vh auto;
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 4%;
  row-gap: 8%;
  justify-content: center;
}

.re-description {
  display: flex;
  flex-direction: column;
  margin: 0vh auto 3vh auto;
}

.taken-foto {
  border-radius: 16px;
  width: 100%;
}

.ps-notifications {
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 17vh);
}

.ps-goals {
  height: calc(100% - 17vh - 50px);
}

@media (min-width: 768px) {
  .notifications-container .services-appbar-container .appbar {
    display: none !important;
  }

  .appbar-no-background .notifications-container .services-appbar-container .appbar {
    display: none !important;
  }

  .notifications-container {
    height: 100% !important;
  }

  .notifications-container .ps-notifications {
    height: 100%;
  }

  .notifications-container .ps-notifications .ps-notifications-container {
    height: 95%;
  }
}

.notifications-container {
  & .nav-bar {
    margin-inline: 12px;
    width: auto;
  }
}
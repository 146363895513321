.delivery-progress {
  display: grid;
  padding: 16px;
  height: 100dvh;
  box-sizing: border-box;
  align-items: baseline;

  &>header {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 24px;
  }

  & .delivery-timeline {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &>p {
      font-weight: bold;
      margin-block: 24px 12px;
    }

    & .delivery-timeline-container {
      overflow-y: auto;
      display: block;
      max-height: 54dvh;
    }
  }
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #6e3356;
}

.loading-logo {
  width: 60%;
  max-width: 300px;
  margin-bottom: 100px;
  animation: zoomAnimation 1s infinite alternate;
}

@keyframes zoomAnimation {
  0% {
    transform: scale(0.80); 
  }
  100% {
    transform: scale(1.3);
  }
}

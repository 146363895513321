/* Estilos para el componente IntegerInput */
.IntegerInput {
  display: flex;
  align-items: center;
}

.number-input {
  width: 70px;
  padding: 8px;
  font-size: 16px;
  text-align: center;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.number-input:focus {
  outline: none;
  border-color: #f90; /* Estilo de foco en color naranja */
  box-shadow: 0 0 3px #f90; /* Sombra en el enfoque */
}

.number-input.disabled {
  background-color: #f0f0f0; /* Color de fondo para el input deshabilitado */
  border-color: #ddd; /* Color de borde para el input deshabilitado */
  color: #888; /* Color de texto para el input deshabilitado */
  pointer-events: none; /* Deshabilitar eventos del input deshabilitado */
}

.number-button {
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  margin-left: 4px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #f90;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.number-button:hover {
  background-color: #ff8d00;
}

.number-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.number-input-contianer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.number-input-content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.title-number-input h3{
  color: #652d65;        /* Color del texto (púrpura) */
  font-weight: 500;      /* Grosor del texto: 400 es más ligero que 500 */
  font-size: 16px;       /* Tamaño de la fuente más pequeño */
  text-align: center;
  margin-bottom: 8px;    
}
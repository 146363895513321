.baseCard{
    box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
    max-width: 130px;
    min-width:125px;
    font-style: normal;
    font-weight: bold;
    color: #652D52;
    -webkit-transition:.4s;
    transition: .4s;
    cursor: pointer;
}

.selected{
    background-color: #fd941b;
}

.iconSmallContainer{
    height: 90%;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-bottom: .5vh;
}

.iconSmall{ 
    width: 40%;
    height: 50%;
    filter:invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
}

.textSmall{   
    font-size: 10px;
    text-align: center;
    color: #652D52;
    width: 100%;
}


.bigCard{
    height:120px;
    width:130px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}


.textBig{
    font-size: 13px;
    text-align: center;
    margin: 0;
}

.iconBig{
    width: 50%;
    height: 50%;
    margin: 0 0 5px 0;
    filter: invert(12%) sepia(8%) saturate(4965%) hue-rotate(286deg) brightness(96%) contrast(85%);
}



.completeCard{   
    width:125px;  
    padding-top: 30px;
    padding-left: 5px;
    border-radius: 12px;
    
}

.titleComplete{
    font-size: 16px;
    text-align: left;
    padding-left: 5px;
    margin: 0;
}

.textComplete{
    font-size: 10px;
    font-weight: normal;
    text-align: left;
    padding-left: 5px;
    padding-bottom: 5px;
    white-space: nowrap; 
    width: 110px; 
    overflow: hidden;
    text-overflow: ellipsis; 
    margin: 0;

}

.iconComplete{
    height:40%;
    width:30%;
    filter:invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
}

.starting-time{
    font-weight: bold;
    font-size: 18px;
    color: #4A2433;
}

.ending-time{
    font-weight: 300;
    font-size: 9px;  
    color: #4A2433; 
}

.timesCard{
    background: #FFFFFF;
    box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    min-width:100px;
    min-height: 50px;
    width: 100%;
    height:10vh;
    max-width: 1000px;
}

.card-times-contianer{
    height: 100%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.selectedTime{
    background-color: #fd941b;
}

.userTypeCard{
    height:80px;
    width:260px;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
}

.card-type{

    font-weight: bold;
    font-size: 18px;
    text-align: center;  
    color: #4A2433;   
    
}

.selectedUser{
    background-color: #fd941b !important;
}


.prop-card-container{
    width: 90%;
    margin: 5% auto 5% auto;
    padding: 2% 0;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-height: 60px;
    cursor: pointer;
}

.prop-card-icon-container{
    width: 25%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.prop-card-icon{
    width: 90%;
    filter:invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
}

.prop-card-info{
    width: 65%;
    align-self: center;
}

.prop-card-name{
    font-weight: bold;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.prop-card-more{
    width: 10%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.prop-card-more-icon{
    width: 80%;
    filter: invert(70%);
}

.prop-card-direccion{
    font-size: 14px;
}

.psStyleBigCard{
    width: 80%;
    max-width: 80%;
    color: #4A2433;
}


.psStyleBigCard:hover{
    width: 80%;
    max-width: 80%;
    background-color: #d1cfcd;
    color: #4A2433;
}

.removeFilter{
    filter: none;
}

@media  (min-width:768px){
    
}

@media(min-width:992px){
    /*.baseCard{
        max-width: 200px;
    }*/
    .completeCard{
        width: 150px;
        max-width: 200px;
    }

    .userTypeCard{
        width: 300px;
        max-width: 350px;
    }

    .textComplete{
        font-size: 12px;
    }
}

@media(min-width:1400px){
    .baseCard{
        width: 200px;
        max-width: 250px;
    }

    .timesCard{
        width: 120px;
        max-width: 120px;
    }

    .options-items-container .selected{
        width: 200px;
        max-width: 250px;
    }

    .userTypeCard{
        width: 300px;
        max-width: 350px;
    }

}



.stepguide-container{
    position: relative;
}

.selected{
    color:#652D52;
    border-bottom: 2px solid #652D52;
    width: 25%;
}

.show{
    visibility: visible;
}

#container-steps{
    display: flex;
    align-items: center;
    justify-content: center;
    width:25px;
}

.hide{
    visibility: hidden;
}


.stepGuideInfo{
    position: absolute;
    width:100%;
    height:85vh;
}

.slider-container{
    width: 100%;
    position: relative;    
  
}

.container-step-usage{
    justify-content: center;
    display: flex;
    align-items: center;
}


.circular-div{
    border-radius:50%;
    border: solid 1px #C0A9B8;    
    background-color: #C0A9B8;
    width: 7px;
    height: 7px;

}

.active-step{
    width: 18px;
    height:7px;
    border-radius:4px;
    background-color:#652D52;
    border: solid 1px #652D52;
}

#container-continue-skip{
    position: relative;
    display: flex;
    justify-content: space-between;
    color:#652D52;
    width: 100%;
}

.btn-omitir{
    font-size: 13px;
}

.btn-continuar{
    font-size: 13px;
    font-weight: bold;
}


@media(min-width:768px){

    .stepGuideInfo{
        height: 78vh;
        width: 55vw;
        margin: 3.5vh auto 0 auto;
    }
}

@media (min-width:1200px){
    .stepGuideInfo{
        width: 41vw;
        margin: 3.5vh 0 0 3vw;
    }
}

@media (min-width:1400px){
    .stepGuideInfo{
        width: 40vw;
        margin: 3.5vh 0 0 5vw;
    }
}


.mediaContent {
  cursor: pointer;
  margin: 1%;
  background-color: #652d52;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  object-fit: cover;
}

.bigContentWrapper {
  width: 100%;
  max-height: 320px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigMedia {
  width: 100%;
  height: 100%;
  object-fit: contain;
  height: 400px;
  width: 400px;
}

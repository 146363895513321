.servicesMenu {
  width: 90%;
  border-bottom: 1px solid #652d52;
  color: #8d8d8d;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  height: 6vh;
  align-content: inherit;
  text-align: center;
  display: flex;
  position: fixed;
  padding-top: 3vh;
  background-color: white;
  z-index: 2;
  max-height: 35px;
}

.menuOption {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 100;
}

.selectedInfo {
  color: #652d52;
  border-bottom: 2px solid #652d52;
  width: 25%;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.servicesInfoContainer {
  position: relative;
}

.containerSlide {
  position: relative;
  width: 100%;
}

.servicesInfo {
  position: absolute;
  display: inline-block;
  width: 90%;
  height: 100%;
  margin-top: 9vh;
}

.riContainer {
  /*height: 65vh;*/
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.ri-btn-container {
  display: grid;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
}

.ri-btn-rechazar,
.ri-btn-rechazar:focus {
  background: rgba(101, 45, 82, 0.1);
  border: 1px solid #652d52;
  width: 100%;
  min-width: 50px;
  height: 40px;
  color: #652d52;
  font-size: 13px;
}

.ri-btn-aceptar,
.ri-btn-aceptar:focus {
  background: #652d52;
  border: 1px solid #652d52;
  width: 100%;
  height: 40px;
  color: #ffffff;
  font-size: 13px;
}

.ri-btn-aceptar-card {
  max-width: 50%;
}

.ri-big-container {
  width: 80%;
  display: grid;
  grid-template-columns: 69% 29%;
  column-gap: 2%;
  background: #ffffff;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 3vh 5%;
  margin: 4% auto 2% auto;
  cursor: pointer;
}

.ri-old {
  padding: 3vh 0;
  border-bottom: 1px solid #f0eaee;
}

.ri-right-container {
  justify-self: end;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #c1abba;
}

.ri-time-now {
  color: #ff8f1c;
}

.informationConteiner-title {
  color: #3e1e2b;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  margin: 2vh auto 0 auto;
}

.sp-request-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3e1e2b;
}

.ri-btn-aceptar-container {
  width: 50%;
}

@media (min-width: 768px) {
  .ri-big-container {
    width: 30%;
    max-height: 18vh;
  }

  .riContainer {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
  }

  .servicesInfo {
    margin-top: 6vh;
  }

  .containerSlide {
    left: 5%;
  }
}

@media (min-width: 1200px) {
  .ri-big-container {
    width: 25%;
    max-height: 20vh;
    padding: 3vh 3%;
  }

  .servicesInfo {
    display: flex;
    margin-top: 4vh;
  }
}

@media (min-width: 1400px) {
  .ri-big-container {
    max-height: 15vh;
  }
}

.pulse-new-message {
  position: absolute;
  top: 0;
  right: -10px;
  height: 6px;
  width: 6px;
  background-color: #fd941b;
  /* Color de fondo del círculo */
  border-radius: 50%;
  animation: pulse 1s ease-out infinite;
  /* Animación */
}

.pulse-new-message::before {
  z-index: 8;
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fd941b;
  /* Color del borde del círculo */
  opacity: 0;
  animation: pulse-border 1s ease-out infinite;
  /* Animación del borde */
}

@keyframes pulse-border {
  0% {
    opacity: 0;
    /* Borde invisible */
  }

  50% {
    opacity: 1;
    /* Borde visible */
  }

  100% {
    opacity: 0;
    /* Borde invisible */
  }
}

.pulse-is-new {
  position: absolute;
  top: 0;
  right: 0;
  height: 6px;
  width: 6px;
  background-color: #652d52;
  /* Color de fondo del círculo */
  border-radius: 50%;
  animation: pulse 1s ease-out infinite;
  /* Animación */
}

.pulse-is-new::before {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #652d52;
  /* Color del borde del círculo */
  opacity: 0;
  animation: pulse-border 1s ease-out infinite;
  /* Animación del borde */
}

@keyframes pulse-border {
  0% {
    transform: scale(0.95);
    /* Escala inicial del círculo */
  }

  50% {
    transform: scale(1.5);
    /* Escala media del círculo */
  }

  100% {
    transform: scale(1.95);
    /* Escala final del círculo */
  }
}

@keyframes pulse-border {
  0% {
    opacity: 0;
    /* Borde invisible */
  }

  50% {
    opacity: 0.5;
    /* Borde visible */
  }

  100% {
    opacity: 0;
    /* Borde invisible */
  }
}

.informationContainer {
  background-color: #eae2e760;
  border-radius: 8px;
}

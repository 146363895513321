.ps-sl-days-items{
    display: flex;
    width: 100%;
    overflow-x: scroll;

}

@media(min-width:768px){
    .ps-sl-days-items{
        justify-content: center;
    
    }
}
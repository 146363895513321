.next-step-container {
  width: 90%;
  margin: 0 auto 2vh auto;
  text-align: center;
}

.ns-next-step-container {
  width: 90%;
  margin: 2vh auto 2vh auto;
}

.categoria-container {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  width: 100%;
}

.new-service-text {
  width: 90%;
  margin: 1vh auto 2vh auto;
  display: flex;
  justify-content: left;
  align-items: center;
  align-content: center;
  color: #4a2433;
  font-size: 16px;
  font-weight: bold;
}

#new-service-icon {
  width: 80%;
  height: 70%;
  filter: invert(11%) sepia(24%) saturate(2292%) hue-rotate(292deg)
    brightness(100%) contrast(84%);
}

.categoria-types {
  display: flex;
  overflow-x: scroll;
  min-height: fit-content;
}

.new-service-type {
  margin: 2vh 3vw;
  min-height: 70px;
}

.descripcion-input {
  width: 87%;
  margin: auto;
  display: flex;
  justify-content: left;
  min-width: 100px;
  height: 100px;
  box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  min-height: 30px;
  padding-left: 3%;
  align-items: flex-end;
}

.dano-foto-container {
  margin: 4vh 0 4vh 0;
}

.dano-foto {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.add-foto-container,
.foto-source-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.add-foto-container {
  margin-right: 5px;
}

.foto-source-container {
  position: relative;
  margin-right: 5px;
  margin-bottom: 7px;
  cursor: pointer;
}

.deletePhoto {
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: white;
  color: #4a2433;
  cursor: pointer;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 78%;
  top: -10%;
}

.taken-foto-container {
  display: flex;
  overflow-x: scroll;
  height: 65px;
  width: 65px;
  margin: 2vw;
}

.text-service-details {
  width: 90%;
  margin: 2vh auto 3vh auto;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #4a2433;
  font-size: 16px;
  font-weight: bold;
}

.img-text-service-details {
  width: 80%;
  height: 70%;
  filter: invert(11%) sepia(24%) saturate(2292%) hue-rotate(292deg)
    brightness(100%) contrast(84%);
}

.text-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.note-details {
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #fd941b;
}

.service-description {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 3vh auto 3vh auto;
}

.title-description {
  font-weight: bold;
  font-size: 16px;
  color: #3e1e2b;
  text-align: center;
  margin-bottom: 2vh;
}

.text-description {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  min-height: fit-content;
}

.service-fotos {
  /*margin: 3vh 0 3vh 0;*/
  margin: 0 0 3vh 0;
}

.service-foto-title {
  width: 90%;
  margin: 0 auto 2vh auto;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #3e1e2b;
}

.new-service-info-container {
  width: 90%;
  margin: 0 auto 7vh auto;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 4%;
  row-gap: 8%;
  align-content: center;
}

.leftSide {
  text-align: right;
  /*max-width: 80%;
    margin-left: 20%;*/
}

.rightSide {
  text-align: left;
}

.new-service-info-title {
  font-weight: bold;
  font-size: 16px;
  color: #3e1e2b;
  margin-bottom: 4%;
}

.new-service-info-text {
  font-weight: 300;
  font-size: 12px;
  color: #361a26;
}

.new-service-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% + 50px);
  background-color: #361a260f;
}

.service-information-container {
  height: 100vh;
  overflow-y: scroll;
  min-height: fit-content;
}

.new-entry-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eeeeee;
}

.request-sent-info {
  height: 65vh;
  display: flex;
  align-content: center;
}

.schedule-urgency-container {
  display: flex;
  width: 90%;
  margin: 2vh auto 2vh auto;
}

.urgency-types-container {
  display: flex;
  margin-left: 2%;
  width: 50%;
  height: 20px;
  justify-content: flex-end;
}

.urgency-icon-container {
  width: 10%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.urgency-title {
  width: 40%;
  font-weight: bold;
  font-size: 18px;
  color: #3e1e2b;
}

.urgency-types {
  margin-left: 4%;
  font-size: 10px;
  text-align: center;
  color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1% 2%;
  cursor: pointer;
}

.urgency-type-selected {
  background-color: #fd941b !important;
  border-radius: 9.5px;
  color: #3e1e2b !important;
}

.schedule-calendar-container {
  width: 90%;
  margin: 1vh auto 2vh auto;
  display: flex;
  flex-direction: column;
}
.schedule-time-container {
  width: 90%;
  margin: 2vh auto 4vh auto;
  display: flex;
  flex-direction: column;
}

.schedule-text {
  width: 90%;
  font-weight: bold;
  font-size: 18px;
  color: #3e1e2b;
  align-self: center;
}

.schedule-icon-container {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-info {
  display: flex;
  width: 100%;
  margin: 2vh 0 2vh 0;
}

.time-text {
  margin: 2vh 0 4vh 10%;
  font-weight: bold;
  font-size: 18px;
  color: #3e1e2b;
}

.schedule-time {
  display: grid;
  width: 80%;
  margin: auto;
  grid-template-columns: 48% 48%;
  row-gap: 2vh;
  column-gap: 2vh;
}

.schedule-icons {
  width: 90%;
  max-width: 25px;
  height: 70%;
  filter: invert(11%) sepia(24%) saturate(2292%) hue-rotate(292deg)
    brightness(100%) contrast(84%);
}

.schedule-calendar {
  display: flex;
  justify-content: center;
}

.urgency-note-container {
  width: 90%;
  margin: auto;
  display: flex;
  align-content: center;
}

.urgency-note-icon {
  width: 60%;
  height: 70%;
  filter: invert(66%) sepia(23%) saturate(3952%) hue-rotate(347deg)
    brightness(102%) contrast(98%);
}

.urgency-note {
  font-size: 12px;
  line-height: 11px;
  color: #fd941b;
  display: flex;
  align-items: center;
}

.urgency-note span {
  font-weight: 600;
}

.container-general {
  height: calc(100% - 17vh);
  overflow-y: scroll;
}

.service-provider-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.instabasica-container {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto 2vh auto;
}

.add-instabasica-icon {
  margin-right: 5px;
  filter: invert(66%) sepia(23%) saturate(3952%) hue-rotate(347deg)
    brightness(102%) contrast(98%);
  width: 20px;
}

.instabasica-text {
  font-size: 12px;
  color: #fd941b;
}

@media (min-width: 768px) {
  .new-service-container {
    height: 100% !important;
    background: #ffffff;
  }

  .new-service-type {
    margin: 2vh 20px;
  }

  .container-general {
    height: calc(100% - 11vh);
  }

  .next-step-container {
    text-align: center;
  }

  .ns-next-step-container {
    text-align: center;
  }

  .schedule-calendar {
    max-width: 400px;
    /*margin-left: auto;*/
    margin: 0 auto;
  }
}

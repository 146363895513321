.register-info-text {
    font-size: 18px;
    color: #4A2433;
    width: 90%;
    margin: 2vh auto 2vh auto;
    color: #4A2433;
    font-weight: bold;
}

.register-prop-info-text {
    font-size: 18px;
    color: #4A2433;
    width: 90%;
    margin: 3vh auto 3vh auto;
    color: #4A2433;
    font-weight: bold;
}

.foto-container {
    width: 100%;
    display: grid;
    justify-content: center;
    margin: 0 auto 2.5vh auto;
}


.foto-btn {
    margin-right: 10px;
    width: 100%;
    height: 100px;
}

.foto-text {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #3f3d3d;
}

.register-info-inputs {
    width: 90%;
    margin: auto;
    overflow-y: scroll;
    /*height: 100%;*/
}

@media(min-width:768px) {
    .register-info-inputs {
        /*height: calc(100% - 18vh);*/
        height: calc(100% - 23vh);
    }
}

@media(min-width:1400px) {
    .register-info-inputs {
        height: calc(100% - 18vh);
    }
}
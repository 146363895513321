.new-advance {
  height: 100dvh;
  padding-left: 12px;
  padding-right: 12px;
  overflow-y: hidden;
  background-color: #f2edf1;

  & .new-advance-body {
    display: flex;
    flex-direction: column;

    & .filter-wrapper {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-block: 20px;

      & .ant-btn {
        margin: 0px;
      }

      & .new-advance-filter {
        background-color: white;
        width: 100%;
        height: 5dvh;
      }
    }

    & .new-advance-list {
      height: calc(100dvh - 5dvh - 20px);

      .none-list {
        background-color: white;
        height: 90vh;
        display: flex;
        justify-content: center;
        border-radius: 8px;
      }


      &:has(.list-item) {
        display: grid;
        gap: 18px;
        grid-template-columns: repeat(autofill, minmax(300px, 1fr));
        overflow-y: auto;

        @media (min-width: 768px) {
          grid-template-columns: repeat(3, minmax(400px, 1fr));
          /* Subir a 4 columnas en pantallas mayores a tablet */
        }
      }

      & .list-item {
        &:is(:last-child) {
          margin-bottom: 20dvh;
        }

        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-inline: auto;
        padding: 24px;
        border: 1px solid #e2e2e2;
        max-height: 240px;
        border-radius: 8px;
        align-items: center;
        background-color: white;
        cursor: pointer;

        .id-tag {
          position: absolute;
          top: 20px;
          right: 20px;
        }

        & .item-title {
          font-size: 12px;
          word-break: break-all;
          text-align: center;
          display: block;

          h3 {
            font-size: 18px;
            font-weight: 600;
            color: #652d52;
            text-align: center;
          }
        }

        .item-location {
          font-size: 16px;
          text-align: center;
          align-items: center;

          color: #652d52;
          font-weight: 600;
          display: flex;

          img {
            margin-right: 10px;
            filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg) brightness(87%) contrast(88%);
          }

          justify-content: center;
        }


        & .item-status {
          position: absolute;
          top: 20px;
          left: 20px;
          font-size: 14px;
          color: white;
          padding: 4px 14px;
          border-radius: 12px;
          line-height: normal;
        }

        & .item-progress-bar {
          margin-block: auto 0px;
        }
      }
    }
  }
}
.baseTextarea{
    background-color: transparent;
    width: 80%;
    resize: none;
    max-height:100px;
    min-width:100px;
    height: 80%;
    align-items: center;
    min-height: 30px;
    outline: none;
    border: none;
    border-top-right-radius: 13px;
    padding: 3% 0 0 1%;
    font-size: 14px;
    color:#4A2433;
}

.containerIconTextarea{
    width: 100%;
    display: flex;
    justify-content: left;
    min-width:100px;
    height: 100px;
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    min-height: 30px;
    padding-top: 1%;

}

.containerIconText{
    width: 20%;
    display: flex;
    justify-content: center;
    height: 80%;
    padding-top: 2%;
}

.iconTextarea{
    width: 75%;
    max-width: 25px;
}

.service-textarea{
    width: 95%;
}
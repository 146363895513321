.file-selector-popover {
  max-width: 368px;

  & button {
    box-shadow: none;
  }

  & .file-selector-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .close-file-upload-btn {
      align-self: flex-end;
    }

    & .file-selector-upload {
      display: flex;
      flex-direction: column;
      position: relative;

      & .upload-btn {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        height: 100%;
        padding: 4px 8px;
        border: 1px dashed color-mix(in srgb, transparent 60%, gray);
        box-sizing: border-box;

        &:is(:hover, :focus) {
          border-color: #652d52;
        }
      }

      & .ant-upload {
        border: none;
      }

      & .ant-upload-list-item {
        padding: 0px;
        overflow: hidden;

        &::before {
          width: 100% !important;
          height: 100% !important;
        }

        & .ant-upload-list-item-actions {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 12px;
          opacity: 1;

          &>* {
            opacity: 1;
          }

          & .anticon {
            & svg {
              height: 32px;
              width: 32px;
            }
          }
        }
      }
    }

    & .ant-upload-wrapper {
      display: flex;

      & .ant-upload-list {
        display: flex;
        overflow: clip auto;
        max-height: 400px;
        scroll-behavior: smooth;
      }
    }
  }

  & .send-file-btn {
    font-size: 14px;
  }
}

.file-preview-popover {
  max-width: 320px;

  & .file-selector-preview-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .file-selector-preview {
      max-height: 360px;
      object-fit: contain;
      border-radius: 6px;
    }

    & .close-file-preview-btn {
      align-self: flex-end;
    }
  }
}

.css-dev-only-do-not-override-3yo5wf.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
  opacity: 1;
}
.register-type-container {
  margin-top: 4%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  grid-gap: 10px;
  grid-row-gap: -5px; 
  width: 100%;
}

.roles-dropdown {
  margin: 0 10px;
}

.roles-dropdown select{
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #652D52;
  background: #F0EAEE;
  color: #652D52;
  font-size: 14px;
  padding: 0 10px;
  outline: none;
}

.register-type{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bitacle-phase-header {
  display: flex;
  align-items: center;
  gap: 12px;

  & .service-info-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    border-radius: var(--border-radius);
    padding: var(--separation);
    border: var(--border);

    & .service-info {
      color: #652d52;
      line-height: 1.1;

      & .info-name {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }

      & .info-address {
        font-size: 14px;
        color: color-mix(in srgb, gray 95%, #652d52);
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }
  }
}
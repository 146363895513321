.floating-panel-new-advance-form {
  & .adm-floating-panel-header {
    background-color: #652d52;
  }

  & .adm-floating-panel-content {
    display: flex;
    flex-direction: column;
    height: fit-content;
    scrollbar-width: thin;

    & .new-advance-wrapper {
      display: flex;
      padding: 12px;
      flex-direction: column;
      gap: 24px;
      height: 100%;
      box-sizing: border-box;

      & .new-advance-form-header {
        display: flex;
        gap: 4px 18px;
        align-items: center;
        color: #652d52;
        z-index: 1;
        position: sticky;
        top: 0px;
        background-color: #fff;
        padding: 6px 0px;
        box-sizing: border-box;
        justify-content: space-between;

        &>.close-btn-floating-panel {
          order: 2;
          align-self: flex-start;
          box-shadow: none;
          padding: 6px;
          border-radius: 50%;

          & .ant-btn-icon {
            display: flex;

            & svg {
              height: 24px;
              width: 24px;
              color: #fff;
            }
          }
        }

        & .advance-title {
          display: flex;
          gap: 4px 12px;
          align-items: center;
          width: fit-content;
          order: 1;
          margin-inline: auto;

          &>h1 {
            margin: 0px;
            font-size: 20px;
            order: 2;
            text-transform: lowercase;
            color: #652d52;
            letter-spacing: 0.5px;
            width: fit-content;

            &::first-letter {
              text-transform: uppercase;
            }
          }

          &>svg {
            height: 38px;
            width: 38px;
            order: 1;
            stroke-width: 2px;
            filter: none;
            color: color-mix(in srgb, transparent 0%, #fd941b);
          }
        }
      }

      & .update-form {
        display: flex;
        flex-direction: column;
        padding: 0px;
        height: 100%;
        gap: 12px;

        & .percentage-update {
          display: flex;
          flex-direction: column;

          & .percentage-container {
            display: flex;
            flex-direction: column;
            padding-inline: 4px;
            gap: 12px;
            box-sizing: border-box;
            width: 100%;

            & :first-child {}

            & :last-child {
              margin-bottom: 0px;
            }
          }

          & .complete-all-btn {
            width: 100%;
            margin-top: 12px;
          }
        }

        & .add-comment-wrapper {
          display: flex;
          gap: 12px;
          flex-direction: column;
          margin-bottom: 12px;

          &:has(.optional-comment) {
            margin-bottom: 0px;
          }

          &>header {
            display: flex;
            align-items: center;

            &>span {
              font-size: 15px;
              margin-right: 8px;
            }

            & .switch-comment {
              width: fit-content;
            }
          }

          & .optional-comment {
            height: 120px;
            field-sizing: content;
            resize: none;
          }
        }

        & .add-media-wrapper {
          &>span {
            font-size: 15px;
          }
        }

        & button[type="submit"] {
          margin-block: auto 12px;
          padding: 24px 0px;
          box-shadow: none;
        }
      }

      & .nav-bar {
        align-self: self-end;
        margin-top: auto;
      }

      .fixed-cuantity-wrapper {
        display: flex;
        flex-direction: column;

        & .slider-cuantity-selector {
          margin-bottom: 24px;
        }

        & .quantity-selector {
          display: flex;
          align-items: center;
          border: 1px solid #ddd;
          border-radius: 6px;
          width: 100%;

          & .quantity-button:focus,
          & .quantity-input:focus {
            outline: none;
          }

          & .quantity-button {
            background-color: white;
            border: none;
            padding: 5px 10px;
            cursor: pointer;
            font-size: 18px;
            flex: 1;
            border: 1px solid color-mix(in srgb, transparent 60%, currentColor);

            &:first-child {
              color: red;
            }

            &:last-child {
              color: #007bff;
            }

            &:disabled {
              color: #ccc;
              cursor: not-allowed;
            }
          }

          & .quantity-input {
            border: none;
            font-size: 18px;
            outline: none;
            border-radius: 0px;
            flex: 2;
            display: block;

            & input {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.background-container {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100vw;
    /*height:100vh;
    height: calc(82vh + 45px);*/
    height: -webkit-fill-available;
    position: absolute;
    top: 0;
    overflow-y: hidden;
}

.modal-container {
    border-radius: 9px;
    padding: 18px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.35);

    max-width: 80vw;

}

.blur-root {
    filter: blur(3px);
    height: 100% !important;
}
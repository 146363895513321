.progress-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.progress-bar {
  display: flex;
  align-items: center;
  height: 20px;
  width: 100%;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
}

.progress-bar-phase-names {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  color: gray;
}

.progress-bar-phase-name {
  flex: 1;
  text-align: center;
}

.progress-bar-phase {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  color: #fff;
  padding: 0 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

.progress-bar-phase:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.progress-bar-phase:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.progress-bar-progress {
  height: 100%;
}

.progress-bar-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.progress-bar-controls button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

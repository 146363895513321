.assistans-container{
    width: 80vw;
    /*max-height: 60vh;
    min-height: 40vh;
    overflow-y: scroll;*/
    position: relative;

}

.helpers-cards-container{
    max-height: 60vh;
    min-height: 40vh;
    overflow-y: scroll;
}

.assistants-list-button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2vh 0;
}

.assistants-invitation{
    display: flex;
    font-size: 14px;
    color: #FD941B;
    align-items: center;
    font-weight: 600;
}

.ai-icon-container{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 1vw;
}

.ai-icon{
    filter: invert(69%) sepia(14%) saturate(6901%) hue-rotate(346deg) brightness(101%) contrast(98%);
    width: 70%;
    cursor: pointer;
}

.text-label-sp{
    margin-top: 16px;
}

.title-service-provider-helpers{
    color: #652D52;
    width: 90%;
    margin: auto;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.ai-text{
    font-size: 14px;
    cursor: pointer;
}

.assistants-buttons-container{
    width: 100%;
    /*position: absolute;
    bottom: 0;*/
}
.ps-services-assistants-container{
    width: 100%;
}

.no-assistants-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    justify-content: center;
    margin-top: 5%;
}

.popup-appbar{
    border-radius: 9px;
    height: 12vh; 
}

.client-services-feedback{
    margin-top: 20vh;
    height: auto;
    margin-left: 5%;
}

.popup-appbar  .big-text-container{
    margin-bottom: 5px;
}



@media(min-width:768px){
    .assistans-container{
        width: auto;  
    }

    .client-services-feedback .no-properties-title{
        font-size: 16px;
    }

    .popup-appbar  .big-text-container{
        margin-bottom: 10px;
    }
}
.desktop-menu {
  background-color: #642c53;
  position: fixed;
  top: 0;
  width: 94vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
  border-radius: 0 0 20px 20px;
}

.logo img {
  width: 120px;
}

.menu-items {
  display: flex;
  gap: 40px;
}

.menu-items button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  gap: 2px;
  font-size: x-large;
  height: fit-content;
  color: white;

  & .ant-btn-icon {
    margin: 0 !important;
  }

  & svg {
    color: white !important;
    align-self: center;
  }

  & p {
    font-size: 14px;
  }
}

.menu-items button:hover {
  background: none !important;
  color: #fd992f !important;

  & svg {
    color: #fd992f !important;
  }
}

/*notification panel */

.notifications-pc-panel-overlay {
  position: fixed;
  top: 90px;
  right: 100px;
  width: 30%;
  max-width: 400px;
  height: 500px;
  overflow: hidden;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.notifications-pc-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .ap-icon {
    margin-left: 25px;
  }
}

.section-notifications {
  overflow: auto;
}

/* SliderInput.css */
.slider-container {
  display: flex;
  flex-direction: column;
}
.slider-content{
  display: flex;
  align-items: center;
  background-color: #fafbff;
  border-radius: 12px;
  padding: 4%;
  gap: 10px;
}
/* Estilos personalizados para el slider */
.slider-input {
  /* Cambia el color del track del slider */
  --slider-track-color: #652d65; /* Morado vinotinto */

  /* Cambia el color del thumb (el control del slider) */
  --slider-thumb-color: #ff8d00; /* Morado vinotinto más oscuro */

  /* Cambia el tamaño del thumb */
  --slider-thumb-size: 20px;

  /* Estilos para ocultar el valor del slider */
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
}

.slider-input:focus {
  outline: none;
}

.slider-input{
  width: 100%;
}

.slider-input::-webkit-slider-runnable-track {
  background: var(--slider-track-color);
  height: 4px; /* Altura del track */
  border-radius: 2px;
}

.slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: var(--slider-thumb-size);
  width: var(--slider-thumb-size);
  background: var(--slider-thumb-color);
  border-radius: 50%; /* Para hacer un círculo */
  margin-top: -8px; /* Ajusta este valor para centrar el thumb verticalmente */
  cursor: pointer; /* Cambia el cursor al estar sobre el thumb */
}

.slider-value {
  min-width: 30px;
  text-align: center;
  color: #ff8d00;
  font-size: 16px;
  font-weight: bold;
}

.title-slider h3 {
  color: #652d65;        /* Color del texto (púrpura) */
  font-weight: 400;      /* Grosor del texto: 400 es más ligero que 500 */
  font-size: 16px;       /* Tamaño de la fuente más pequeño */
  text-align: center;
  margin-bottom: 5px;    /* Espaciado inferior más reducido */
}

.pif-container{
    width: 90%;
    margin: auto;
}

.pif-icon-container{
    width: 20%;
    margin: 0 auto 2vh auto;
    display: flex;
    justify-content: center;
}

.pif-icon{
    width: 100%;
    filter: invert(17%) sepia(14%) saturate(1776%) hue-rotate(285deg) brightness(91%) contrast(95%);
}

.pif-main-text{
    font-weight: 300;
    font-size: 12px;
    color: #3E1E2B;
    text-align: center;
}

.pif-secondary-text{
    font-weight: 300;
    font-size: 12px;
    color: #FD941B;
    text-align: center;
    margin-bottom: 2vh;
}

.bold{
    font-weight: bold;
    margin-bottom: 5vh;
}

.pif-element-container{
    margin-bottom: 3vh;
    max-height: 35px;
    text-align: center;
}

.pif-top-margin{
    margin-top: 8vh;
}

.pif-button-style{
    font-size: 12px;
}

.png-container{
    width: 100%;
    display: flex;
    margin-bottom: 1.5vh;
}

.popup-goal-container{
    width: 70vw;
    max-width: 100%;
    text-align:center;
}

.goal-popup-text{
    font-weight: 300;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3E1E2B;
    margin-bottom: 1.5vh;
}

.goal-input-container{
    width: 100%;
    margin-bottom: 1.5vh;
}

.text-goal-details{
    width: 100%;
    margin: 2vh 0 2vh 0;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #4A2433;
    font-size: 12px;
    font-weight: bold;
}

.img-text-goal-details{
    width: 70%;
    filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg) brightness(87%) contrast(88%);
    min-width: 15px;
}

.deadline-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2vh;
}

.goal-dropdown{
    width: 100%;
    min-width: fit-content !important;
}

.deadline-container-dropdown{
    width: 30%;
}

.popup-calendar{
    max-width: 75vw;
    display: flex;
    flex-direction: column;
}

.btn-select-date-popup{
    margin-top: 15px;
}

.code-input-container{
    margin-top: 3vh;
    width: 100%;
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 45px;
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    align-items: center;
    min-height: 30px;
}

.pif-top-margin-code{
    margin-top: 3vh;
}

.code-input{
    width: 90%;
    margin: 0 auto;
}

.message-subtitle{
    font-weight: bold;
    margin: 15px 0;
}

.popup-options-container{
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.option-container{
    width: 90%;
    margin: auto;
    display: flex;
    box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    border-radius: 16px;
    margin: 1vh auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.option-icon{
    margin-right: 10px;
    filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
}

.option-text{
    font-size: 14px;
    color: #4A2433;
}

.error-text{
    font-size: 12px;
    color: #FD941B;
    margin-top: 10px;
}

.add-content-inst{
    display: flex;
    width: 100%;
    margin: 2vh auto 0 auto;
    align-items: flex-end;

}

.inst-type{
    display: flex;
    flex-direction: column;
    width: 60%;
}

.inst-cant{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.inst-text{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    width: 95%;
}

.box-style{
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1vh 0  1vh 6px;
    width: 90%;
}

.add-instabasica-popup{
    filter: invert(66%) sepia(23%) saturate(3952%) hue-rotate(347deg) brightness(102%) contrast(98%);
    margin-bottom: 5px;
    margin-left: 5px;
}

.inst-selected-container{
    max-height: 30vh;
    overflow-y: scroll;
}

.inst-selected{
    width: 90%;
    display: flex;
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1vh 5%;
    justify-content: space-between;
    margin: 1.5vh 0;
    line-height: 1.3;
}

.inst-selected .text{
    font-size: 12px;
    color: #4A2433;
}

.inst-selected span{
    font-weight: 600;
}

.delete-inst{
    filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
    width: 20px;
}

.helpers-cards-container .si-container-info{
    width: 80%;
}

@media (min-width:768px){
    .helpers-cards-container .si-container-info{
        width: 85%;
    }
}
.pay-modal-space {
  display: flex;
  gap: 10px;
  text-align: center;
  font-size: 18px;
  margin-top: 6px;
}

@media (min-width: 768px) {
  .pay-modal-required {
    & .adm-center-popup-wrap {
      width: 450px;
    }
  }
}

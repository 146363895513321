.tag-info {
    color: #ffffff;
    background-color: #652d52;
    font-weight: 500;
    border-radius: 4px;
    padding-inline: 5px;
    line-height: 2;
    font-size: smaller;
    margin-left: 2px;
    cursor: pointer;
}

.tag-info-mine {
    color: #e95c0c;
    background-color: #ffffff;
    font-weight: 500;
    border-radius: 4px;
    padding-inline: 5px;
    line-height: 2;
    font-size: smaller;
    margin-left: 2px;
    cursor: pointer;
}

.tag-thumbnail {
    display: inline-block;
    overflow: visible;
}

.tag-thumbnail img {
    display: none;
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 65%;
    border-radius: 50%;
    border: solid 2px #652d52;
    z-index: 100;
    box-sizing: border-box;
}

.tag-thumbnail:hover img {
    display: block;
}
.notifications-container {
  width: 100%;
  background-color: transparent;
  height: 100%;
}

.options-notifications-container {
  width: 90%;
  margin: auto;
  overflow-y: scroll;
  /*height: 65vh;*/
  height: calc(100% - 17vh);
  padding-bottom: 90px;
  margin: 2vh auto 2vh auto;
}

@media (min-width: 768px) {
  .options-notifications-container {
    height: 100%;
    padding-bottom: 0;
    width: 95%;
  }
}

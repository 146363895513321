.container-filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
}

@media (max-width: 600px) {
    .input-filter {
        width: 80%;
        margin-bottom: 10px;
    }

    .status-filter {
        width: 80%;
        margin-bottom: 10px;
        padding: 10px;
    }
}

.input-filter {
    background-color: #f7f7f7;
    border-radius: 5px;
    border: 0;
    height: 40px;
    box-shadow: 2px;
}

.input-filter:active, .input-filter:focus {
    border: 0;
    outline: none;

}

.status-filter {
    border: none;
    margin-left: 5px;

}

.status-filter select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

}

#options {
    border-radius: 5%;
    outline: none;
}


#options option {
    background-color: #f0f0f0;
    color: #333;
    padding: 5px;
    font-size: 14px;
    border-bottom: 1px solid #ccc;

}


#options option:checked {
    background-color: #9dacbb;
    color: #fff;
    font-weight: bold;
}

#options option:hover {
    background-color: #9dacbb;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}
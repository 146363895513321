.text-tracking-details{
    width: 90%;
    margin: 2vh auto 3vh auto;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #4A2433;
    font-size: 16px;
    font-weight: bold;
}

.no-entries-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    justify-content: center;
}

.no-entries-icon{
    width:25%;
    filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
    max-width: 150px;
}

.no-entries-title{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #652D52;
    margin-top: 5%;
}

.no-properties-title{
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #652D52;
    margin-bottom: 5%;
}

.no-entries-text{
    font-size: 14px;
    text-align: center;
    color: #652D52;
    margin-top: 5%;
}

.seguimiento-header{
    width: 90%;
    margin: 2vh auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seguimiento-header-title{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
    color: #3E1E2B;
}

.seguimiento-header-title span{
    font-weight: 600;
    font-size: 16px;
    color: orange
}

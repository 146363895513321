.order-cleaning-service {
  height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 24px;
  padding: 12px;
  box-sizing: border-box;
  position: relative;

  .order-submit-btn {
    position: absolute;
    bottom: 20px;
    left: 2.5dvw;
    width: 95dvw;
  }

  .order-submit-btn & .cleaning-service-wrapper {
    display: flex;
    flex-direction: column;

    & h1 {
      font-size: 24px;
      margin: 0px;
    }

    & .cleaning-service-form {
      height: 100%;
      display: flex;
      flex-direction: column;

      & .input-wrapper {
        margin-block: 12px;

        & .date-picker {
          width: 100%;
        }
      }
    }
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px;
  box-sizing: border-box;

  .content-info {
    font-size: 18px;
    font-weight: 400;

    span {
      font-weight: 600;
      color: #652D52;
    }
  }

}

.header {
  font-size: 14px;
  color: #625;
  padding: 20px 20px 0 20px;
  & h1 {
    margin: 0;
  }
}

.card {
  width: auto;
  height: 28vh;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #625;

  .ant-card-body {
    padding: 0;
  }

  .cards-amount-grid {
    display: grid;
    grid-template-columns: 50% 50%;

    & .amount-card {
      border: none;
      background-color: #e9e2e7;
      margin: 6px;
      color: #625;
      font-size: 12px;
      padding: 10px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & p:first-child {
        font-weight: 600;
        font-size: 14px;
      }

      & h3 {
        color: #fd941b;
        font-weight: 600;
        font-size: 28px;
      }
    }

    & .amount-card:first-child {
      grid-column: 1 / 2;
    }
  }

  .content-info {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 10px;

    .ant-carousel {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .carousel-contain {
      padding-inline: 10px;
      border-radius: 10px;
      width: 100;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      & .carousel {
        width: 150px;
        height: auto;
      }

      & .material-image {
        width: 150px;
        height: 110px;
        border: 1px solid #e9e2e7;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    & .info {
      grid-column: 2 / 3;
      padding: 10px;
      place-content: start;
      font-size: 13px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      word-wrap: break-word;

      & h3 {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.inventary-button {
  margin-bottom: 60px;
  background-color: #625;
}

.activities-section-body {
  --separation: 12px;
  --border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
  --border-radius: 12px;
  padding: 0px !important;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0px;
  }

  & .activities-list {
    overflow-y: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    padding: var(--separation);

    & .list-card-divider {
      min-height: 64px;
      width: 1px;
      margin-inline: auto;
      border-left: 2px dashed #fd941b;
      position: sticky;
      top: 0px;
    }

    & .activity-card {
      border: var(--border);
      border-radius: calc(var(--border-radius) / 2);
      padding: calc(var(--separation) / 2);
      user-select: none;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      transition: .2s ease-in-out;
      transition-property: border, background-color;
      position: sticky;
      top: 0px;
      z-index: 1;

      &:hover {
        border-color: #fd941b;
        background-color: color-mix(in srgb, #fff 99%, #fd941b);
        cursor: pointer;
      }

      & .list-card-title {
        font-size: 18px;
        color: #652d52;
        text-align: center;
      }

      & .list-card-responsible {
        font-size: 14px;
        margin-block: 12px 24px;
      }

      & .list-card-date {
        font-size: 12px;
        color: color-mix(in srgb, transparent 60%, #652d52);
        align-self: flex-end;
      }
    }
  }
}
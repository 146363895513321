.accordion {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #ffffff;
  color: #652d52;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease-in-out;
}

.accordion-header:hover {
  background-color: #f5f5f5;
}

.accordion-content{
  padding: 0 10px;
}

.accordion-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
}

.accordion-icon.open {
  transform: rotate(45deg);
}

.accordion-icon-space {
  position: absolute;
  right: 2%;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
}

.accordion-icon-space.open {
  transform: rotate(45deg);
}
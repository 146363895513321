.media-all-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  & .media-all-item-link {
    border: var(--border);
    border-color: color-mix(in srgb, transparent 85%, #652d52);
    border-radius: var(--border-radius);
    overflow: hidden;
    padding: calc(var(--separation) / 2);
    display: flex;
    flex-direction: column;
    gap: var(--separation);
    background-color: color-mix(in srgb, transparent 97%, #652d52);
    width: 100%;

    & .media-all-image {
      border-radius: calc(var(--border-radius) / 2);
      object-fit: cover;
    }

    & .media-all-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      & .header-media-all-info {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;

        & .media-all-title {
          font-size: 18px;
        }
        & .media-message-quoted{
          font-style: italic; 
          opacity: 0.8; 
          color: #555; 
          border-left: 4px solid #ccc; 
          padding-left: 10px; 
          margin: 10px 0; 
          font-size: 0.5rem; 
          line-height: 1.5; 
        }
      }

      & .media-all-date {
        font-size: 12px;
        color: color-mix(in srgb, transparent 60%, gray);
        margin-block: auto 0px;
        line-height: normal;
      }
    }
  }

  & .media-all-item {
    border: var(--border);
    border-color: color-mix(in srgb, transparent 85%, #652d52);
    border-radius: var(--border-radius);
    overflow: hidden;
    padding: calc(var(--separation) / 2);
    display: flex;
    flex-direction: column;
    gap: var(--separation);
    background-color: color-mix(in srgb, transparent 97%, #652d52);
    width: 45%;

    & .media-all-image {
      border-radius: calc(var(--border-radius) / 2);
      object-fit: cover;
    }

    & .media-all-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      & .header-media-all-info {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;

        & .media-all-title {
          font-size: 18px;
        }
        & .media-message-quoted{
          font-style: italic; 
          opacity: 0.8; 
          color: #555; 
          border-left: 4px solid #ccc; 
          padding-left: 10px; 
          margin: 10px 0; 
          font-size: 0.5rem; 
          line-height: 1.5; 
        }
      }

      & .media-all-date {
        font-size: 12px;
        color: color-mix(in srgb, transparent 60%, gray);
        margin-block: auto 0px;
        line-height: normal;
      }
    }
  }
}

.media-all-options-btn-popover {
  --color: color-mix(in srgb, transparent 60%, gray);

  & .ant-popover-arrow {
    &::before {
      background-color: var(--color);
    }
  }

  & .ant-popover-content {
    & .ant-popover-inner {
      padding: 0px;
      overflow: hidden;

      & .media-all-popover-list {
        margin: 0px;
        padding: 4px;
        display: flex;
        flex-direction: column;
        background-color: var(--color);
        color: #652d52;

        &>* {
          &:hover {
            background-color: color-mix(in srgb, transparent 40%, #fff);
          }
        }
      }
    }
  }
}
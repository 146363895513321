.date-change-calendar {
    width: 80%;
    display: flex;
    margin: auto;
    justify-content: space-between;
}

.date-change {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    width: 40%;

}

.popup-calendar {
    max-width: 40vw;
    display: flex;
    flex-direction: column;
}

.ag-close-icon-container {
    width: calc(100% - 20px);
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 5px;

}

.ag-icon-x {
    width: 25px !important;
}

.checkbox-item {
    font-size: 16px;
    margin-bottom: 10px;
    color: #652D52;
    display: flex;
    align-items: center;
}

.selectBoxContainer {
    background: #EAE2E7;
    border-radius: 6px;
    padding: 5px;
}

.checkbox-box {
    border: 0.5px solid #B296A8;
    border-radius: 2px;
    width: 12px;
    height: 12px;
    min-width: 12px;
}

.selectBoxActive {
    background-color: #652D52;
}

.admin-filter-input {
    margin-left: 5px;
}

.popup-select-items-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.psi-icon-container {
    width: 30%;
    margin: 0 auto 2vh auto;
    display: flex;
    justify-content: center;
    align-content: center;
}

.psi-icon {
    width: 80%;
    margin: 0;
    filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
    max-height: 55px;
}

.psi-text {
    font-size: 14px;
    color: #3E1E2B;
    text-align: center;
    margin-bottom: 2vh;
}

.prc-reasons-container {
    margin-bottom: 2vh;
    overflow-y: scroll;
}

.textarea-reject-cotizacion {
    background-color: transparent;
    width: 96%;
    resize: none;
    min-width: 100px;
    height: 10vh;
    align-items: center;
    min-height: 50px;
    outline: none;
    border: none;
    border-radius: 13px;
    padding: 3% 0 0 3%;
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #4A2433;
}

.prc-buttons-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2vh;
}

.prc-button {
    width: 40%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    height: 40px;
    font-size: 14px;
}

.prc-button-send {
    background: #652D52;
    color: #F0EAEE;
}

.measure-units-popup-container {
    width: 30vw;
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: space-between;
}

.measure-units-popup {
    height: 90%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

}

.measure-container {
    width: 80%;
    margin: 10px auto;
    box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 10px;
    font-size: 16px;
    color: #652D52;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.measure-selected {
    border: 1px solid #FF8F1C;
}

.ag-close-icon-container-calendar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.save-container {
    width: 80%;
    margin: 20px auto;
}

.all-statistics {
    cursor: pointer;
    margin: 2vh 0;
    background-color: #ECE5E9;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    color: #652D52;
}


@media (min-width:1200px) {
    .popup-calendar, .measure-units-popup-container {
        max-width: 20vw;
    }
}

/* Popover.css */
.popover-container {
    position: relative;
    display: inline-block;
}

.popover-trigger {
    padding: 10px;
    cursor: pointer;
}

.popover-trigger img {
    height: 18px;
    filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%) !important;
}

.popover-content {
    position: absolute;
    top: -100px;
    left: 40px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    border-radius: 4px;
}
.emoji-icons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.recording-icons:hover {
  cursor: pointer;
  opacity: 50%;
}

.emoji-icons.active img {
  opacity: 80%;
}

.emoji-icons:focus {
  opacity: 50%;
}

.emoji-icons img {
  height: 45px;
}

.icons-hover:hover {
  opacity: 50%;
}

.entry-info-container {
  overflow-y: scroll;
  margin: 0 auto;
  min-width: 90%;
  height: 100%;
  z-index: 100;
}

.desktop-container {
  position: relative;
  background-color: #47094d0d;
}

.chat-controller {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 1%;
  width: 98%;
  left: 1%;
  min-height: 120px;
  border-radius: 6px;
  background-color: white;
  z-index: 3;
}

.cei-header-container {
  display: flex;
  margin-top: 6px;
  margin-bottom: 6px;
  min-width: 60%;
  width: 100%;
}

.cei-header-left {
  width: 20%;
  display: flex;
  align-items: center;
}

.cei-header-photo {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
}

.cei-header-right {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.cei-header-title {
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  color: white;
  margin-bottom: 5px;
}

.cei-header-extra-info {
  display: flex;
  align-items: center;
}

.cei-calendar-icon-container {
  width: 10%;
  max-width: 25px;
  display: flex;
  align-items: center;
}

.cei-calendar-icon {
  width: 75%;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%)
    contrast(0%);
}

.cei-date-container {
  display: flex;
  width: 90%;
}

.cei-date {
  font-weight: 600;
  font-size: 14px;
  color: white;
  margin-right: 10px;
}

.cei-description {
  font-weight: 300;
  font-size: 14px;
  color: #652d52;
  margin-bottom: 1vh;
  margin-left: 4%;
  margin-right: 4%;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  margin-top: 3vh;
  white-space: pre-line
}
.cei-comment-container {
  display: flex;
}

.comment-container {
  display: flex !important;
  position: relative;
  border-radius: 6px;
  height: auto;
}

.comment-container-m {
  display: flex;
  position: relative;
  border-radius: 6px;
  height: auto;
  justify-content: flex-end !important;
}

.comment-container-reactions {
  float: right;
  padding: 10px;
  transform: translateX(120px) translateY(-80px);
}

.check-container {
  position: relative;
  align-self: flex-end;
  margin-bottom: -10px;
  margin-right: 3px;
}

.message-check {
  position: absolute;
  bottom: 20px;
}

.comment-photo-container {
  display: flex;
  margin-right: 5px;
  height: fit-content;
}

.comment-photo {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-photo:hover {
  cursor: pointer;
}

.comment-photo-icon {
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg)
    brightness(98%) contrast(88%);
}

.comment-photo-icon-big {
  filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg)
    brightness(87%) contrast(88%);
}

.comment-info-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 20%;
  overflow: hidden;
  word-wrap: break-word;
}

.botton-info-container.active {
  background-color: #aaa7a7;
}

@media (min-width: 768px) {
  .comment-info-container {
    max-width: 300px;
  }
}

.animation-emoji {
  background-color: #3e1e2b;
}

.comment-user-info {
  display: flex;
  align-items: center;
  min-height: 20px;
}

.comment-user-name {
  font-weight: 600;
  font-size: 12px;
  color: #652d52;
  height: 100%;
  margin-right: 10px;
}

.commenr-user-date {
  font-size: 10px;
  color: #656565;
  align-self: flex-end;
  margin-right: 5px;
}

.comment-user-emoji-container {
  position: absolute;
  margin: 2px;
  right: 0;
  top: 0;
  width: 5%;
  height: 28%;
  display: flex;
}

.comment-user-emoji {
  width: 80%;
  filter: invert(80%) sepia(34%) saturate(7079%) hue-rotate(345deg)
    brightness(100%) contrast(101%);
  min-width: 14px;
  max-width: 18px;
  margin-right: 5px;
}

.botton-info-container {
  font-weight: 300;
  font-size: 14px;
  color: #652d52;
  max-width: fit-content;
  padding-top: 12px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  position: relative;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.header-options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calificacion-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vh;
}

.calificacion-title {
  font-weight: 600;
  font-size: 12px;
  color: #652d52;
}

.calificacion-icons-container {
  display: flex;
}

.calificacion-icon {
  filter: invert(80%);
  width: 47%;
  min-width: 19px;
  max-width: 25px;
  margin-left: 5px;
}

.calificacion-icon-selected {
  filter: invert(80%) sepia(34%) saturate(7079%) hue-rotate(345deg)
    brightness(100%) contrast(101%);
}

.graphic-evidence-container {
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 2%;
  margin: auto 4%;
  margin-bottom: 5px;
  justify-items: stretch;
  margin-bottom: 5vh;
}

.graphic-evidence-container img {
  width: 100%;
  height: 98%;
  border-radius: 12px;
  margin-bottom: 4%;
}

.evidence-container {
  width: 100%;
}

.graphic-evidence-container img {
  width: 100%;
  height: 98%;
  border-radius: 12px;
  margin-bottom: 4%;
  z-index: 2;
}

.evidence-photo:nth-child(even) {
  border-radius: 12px;
}

.ge-big-conainer {
  width: 100%;
  display: flex;
}

.new-service-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #47094d0d;
  position: relative;
}

.textarea-reject-cotizacion {
  background-color: transparent;
  width: 97%;
  resize: none;
  min-width: 100px;
  height: 50px;
  align-items: center;
  min-height: 50px;
  outline: none;
  border: none;
  border-radius: 13px;
  padding: 3% 0 0 3%;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.cei-textarea::placeholder {
  color: #3e1e2b;
}

.cei-textarea {
  background-color: transparent;
  width: 96%;
  height: auto;
  resize: none;
  min-width: 100px;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 7px;
  font-size: 14px;
  padding: 1% 0 0 3%;
}

.time-line-ctnr .time-line > .time-label > span,
.time-line-ctnr .time-line > li > .time-line-item .time {
  display: none !important;
}

.time-line-ctnr .time-line:before {
  background-color: #eaeaea !;
  visibility: hidden !important;
}

.single-service .time-line-ctnr .time-line:before {
  background-color: transparent !important;
}

.time-line-ctnr .time-line > li > .fa {
  border: 2px solid #dcdcde !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 2.95652px #dcdcde;
  top: 40% !important;
  height: 10px !important;
  width: 10px !important;
  left: 17px !important;
}

.popup-reactions {
  min-width: 100px;
  width: auto;
  height: 30px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  margin-left: 20px;
  flex-direction: column;
  flex-wrap: wrap;
}

.popup-reactions.left {
  float: right;
  transform: translateX(-300px);
  background-color: #3e1e2b;
}

.popup-reactions.right {
  float: left;
  transform: translateX(0s);
  background-color: #3e1e2b;
}

.reactions-show {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  position: absolute;
  background-color: #fff;
}

.hidden-comment-emoji {
  display: flex;
  align-items: center;
}

.hidden-comment-emoji-mine {
  display: flex;
  align-items: center;
}

.time-line-ctnr .time-line > li > .time-line-item .time-line-header {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #ece8ec;
}

.time-line-ctnr .time-line > li > .time-line-item {
  box-shadow: none !important;
}

.time-line-ctnr .time-line > li > .time-line-item .time-line-header {
  border-bottom: none !important;
}

.time-line-ctnr .time-line > li > .time-line-item .time-line-header {
  background-color: #ececec4f !important;
}

.services-done-container .time-line-ctnr .time-line > li > .fa {
  border: 2px solid #fd941b !important;
  background-color: #fd941b !important;
  box-shadow: 0px 0px 2.95652px #ff8f1c;
  background-image: url("../../assets/icons/svg/checkwhite.svg");
  background-position: center;
  background-repeat: no-repeat;
  top: 40% !important;
  /*left: 26px !important;*/
  left: 15px !important;
  height: 15px !important;
  width: 15px !important;
}

.time-line-item-container {
  display: flex;
  margin-top: 20px;
  position: relative;
}

.hour-item {
  width: 35%;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  color: #4a2433;
  align-self: center;
  margin-right: 2vw;
  min-width: 64px;
}

.content-item-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1.6vh 5px;
  line-height: 1.5;
  max-width: 184px;
}

.item-title {
  font-weight: bold;
  font-size: 12px;
  align-items: center;
  color: #4a2433;
  word-wrap: break-word;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.content-item-container:hover {
  transform: translateY(0) translateX(5%);
  transition: 0.4s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  background-color: #a8a8a8d2;
}

.person-info {
  display: flex;
  align-items: center;
  margin: 4px 2px 2px 2px;
}

.person-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.person-name {
  font-size: 0.7rem;
  font-weight: 400;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-content {
  font-weight: 300;
  font-size: 12px;
  color: #3e1e2b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  right: 6px;
}

.time-line-item-selected .item-content {
  color: white;
}

.services-tracker-container {
  display: flex;
  width: 90%;
  margin: auto;
}

.date-container {
  width: 10%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: center;
}

.ps-services-container {
  width: 100%;
}

.date-number {
  font-weight: 600;
  font-size: 14px;
  color: #4a2433;
}

.date-name {
  font-size: 14px;
  color: #4a2433;
}

.sub-title-list {
  font-weight: bold;
  font-size: 14px;
  color: #3e1e2b;
  width: 90%;
  margin: 2vh auto;
}

.time-line-ctnr .time-line:before {
  left: 21px !important;
}

.time-line-ctnr .time-line > li > .time-line-item {
  margin-left: 30px !important;
}

.new-entry-button .iconText {
  margin-right: 5px;
}

.new-entry-button .fowardIcon {
  width: 18px;
  height: 18px;
}

.new-entry-button .iconBtn {
  justify-content: center;
  padding: 0;
}

.time-line-item-selected {
  background-color: #ff8f1c;
  box-shadow: 0px 2.59px 23.65px rgba(255, 218, 179, 0.6);
  border: 5px solid #ffdab3;
}

.time-line-item-selected .item-title {
  color: white;
}

.time-line-item-selected .item-content {
  color: white;
}

.hide-comment {
  opacity: 0.5;
}

.empty-entries-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-entries-icon {
  width: 15%;
  filter: invert(17%) sepia(48%) saturate(1189%) hue-rotate(274deg)
    brightness(93%) contrast(84%);
}

.empty-entries-text {
  font-weight: bold;
  font-size: 20px;
  color: #652d52;
  width: 50%;
  text-align: center;
}

.cei-description span {
  font-weight: bold;
}

.unexpected-icon {
  max-width: 20px;
  margin-left: 10px;
  filter: invert(65%) sepia(43%) saturate(2234%) hue-rotate(346deg)
    brightness(101%) contrast(99%);
  cursor: pointer;
}

.delete-entry-container {
  width: 10%;
  display: flex;
  justify-content: center;
  max-width: 25px;
}

.delete-entry-icon {
  width: 90%;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%)
    contrast(0%);
}

.delete-entry-icon:hover {
  cursor: pointer;
  opacity: 60%;
  transform: scale(1.1);
}

.unexpected-background {
  background-color: red;
}

.item-content-unexpected {
  color: white;
}

.item-title-unexpected {
  color: white;
}

.header-info-container {
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  background-color: #652d52;
  justify-content: flex-start;
  backdrop-filter: blur(10px);
  width: inherit;
  z-index: 3;
  border-radius: 4px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.mark-as-read {
  padding: 8px;
  border-radius: 4px;
  background-color: #fd941b;
  color: white;
  align-self: center;
  font-weight: bold;
  font-size: 14px;
}

.mark-as-read:hover {
  background-color: #ff8f1c;
  cursor: pointer;
}

.message-info-detail {
  display: none;
  flex-direction: column;
  align-items: flex-start;
}

.separate-line {
  width: 100%;
  height: 1px;
  background-color: #b8b8b851;
  margin-bottom: 5px;
  margin-left: -3px;
}

.message-info-detail span {
  font-size: 10px;
  color: #3e1e2b;
  font-weight: 300;
  margin-bottom: 5px;
}

.read-detail span {
  margin: 5px;
}

.active {
  display: flex;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.active.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.comment-container {
  border-radius: 6px;
  height: auto;
}

.pulse {
  position: relative;
  animation: pulse 3s ease-in-out infinite;
  font-weight: 300;
  font-size: 12px;
  max-width: -moz-fit-content;
  padding: 10px;
  padding-left: 10px;
  position: relative;
  background-color: white;
  color: #3e1e2b;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.pulse::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 15px 10px 0;
  border-color: transparent white transparent transparent;
}

@keyframes pulse {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }
}

@keyframes pulse-border {
  0% {
    opacity: 0.5;
    /* Opacidad inicial del borde */
    transform: scale(1);
    /* Escala inicial del borde */
  }

  50% {
    opacity: 0;
    /* Opacidad media del borde */
    transform: scale(1.5);
    /* Escala media del borde */
  }

  100% {
    opacity: 0;
    /* Opacidad final del borde */
    transform: scale(2);
    /* Escala final del borde */
  }
}

.time-container-pulse {
  position: absolute;
  height: 6px;
  width: 6px;
  background-color: #fd941b;
  /* Color de fondo del círculo */
  right: -6px;
  top: -4px;
  border-radius: 50%;
  animation: pulse 1s ease-out infinite;
  /* Animación */
}

.time-container-pulse::before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #fd941b;
  /* Color del borde del círculo */
  opacity: 0;
  animation: pulse-border 1s ease-out infinite;
  /* Animación del borde */
}

@keyframes time-container-pulse {
  0% {
    transform: scale(0.95);
    /* Escala inicial del círculo */
  }

  50% {
    transform: scale(1);
    /* Escala media del círculo */
  }

  100% {
    transform: scale(0.95);
    /* Escala final del círculo */
  }
}

@keyframes time-container-pulse-border {
  0% {
    opacity: 0.5;
    /* Opacidad inicial del borde */
    transform: scale(1);
    /* Escala inicial del borde */
  }

  50% {
    opacity: 0;
    /* Opacidad media del borde */
    transform: scale(1.5);
    /* Escala media del borde */
  }

  100% {
    opacity: 0;
    /* Opacidad final del borde */
    transform: scale(2);
    /* Escala final del borde */
  }
}

.time-container-pulse-carpenty {
  position: absolute;
  height: 6px;
  width: 6px;
  background-color: #ab914480;
  border: solid 0.5px #ab9144;
  right: -10px;
  top: -10px;
  border-radius: 50%;
  animation: pulse 1s ease-out infinite;
  box-sizing: border-box;
}

.time-container-pulse-carpenty::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ab9144;
  opacity: 0;
  animation: pulse-border 1s ease-out infinite;
}

.time-container-pulse-is-new {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 6px;
  width: 6px;
  background-color: #652d52;
  /* Color de fondo del círculo */
  border-radius: 50%;
  animation: pulse 1s ease-out infinite;
  /* Animación */
}

.time-container-pulse-is-new::before {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #652d52;
  opacity: 0;
  animation: pulse-border 1s ease-out infinite;
}

@keyframes time-container-pulse-is-new {
  0% {
    transform: scale(0.95);
    /* Escala inicial del círculo */
  }

  50% {
    transform: scale(1);
    /* Escala media del círculo */
  }

  100% {
    transform: scale(0.95);
    /* Escala final del círculo */
  }
}

@keyframes time-container-pulse-is-new-border {
  0% {
    opacity: 0.5;
    /* Opacidad inicial del borde */
    transform: scale(1);
    /* Escala inicial del borde */
  }

  50% {
    opacity: 0;
    /* Opacidad media del borde */
    transform: scale(1.5);
    /* Escala media del borde */
  }

  100% {
    opacity: 0;
    /* Opacidad final del borde */
    transform: scale(2);
    /* Escala final del borde */
  }
}

.content {
  display: flex;
  font-weight: 400;
  flex-direction: column;
  align-items: right;
  width: 100%;
  height: 100%;
}

.content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  word-wrap: break-word;
}

.title-entrie {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-bottom: 5px;
}

.circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fd941b;
  margin-right: 5px;
}

.time-line-item-selected .circle {
  background-color: #652d52;
}

.hidden-entry {
  background-color: #3e1e2b5e;
}

.new-message-indicator {
  position: absolute;
  top: 0;
  right: -10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fd941b;
  transform: translate(50%, -50%);
}

.new-message-indicator::before {
  z-index: 8;
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fd941b;
  opacity: 0;
  animation: pulse-border 1s ease-out infinite;
}

@keyframes pulse-border {
  0% {
    opacity: 0;
    /* Borde invisible */
  }

  50% {
    opacity: 1;
    /* Borde visible */
  }

  100% {
    opacity: 0;
    /* Borde invisible */
  }
}

.time-line-phase-container {
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
  position: relative;
  align-items: flex-end;
  transition: all 0.2s ease-in-out;
  margin-left: 2%;
}

.time-line-phase-container:hover {
  background-color: #e8e8e8;
}

.entries-list-container {
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.show-entries {
  opacity: 1;
  transition: all 0.6s ease-in-out;
  transition-duration: 0.5s;
  transition-delay: 0.3s;
}

.time-line-phase-container {
  font-size: 12px;
  width: 50%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.time-line-phase-container {
  transition: width 0.5s ease;
}

.time-line-phase-container.show-entries {
  width: 100%;
}

.check-icon-message {
  display: flex;
  align-items: center;
}

.check-icon-message:hover {
  cursor: pointer;
}

.comment-container-mine {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  padding-right: 5px;
  border-radius: 6px;
  height: auto;
}

.comment-container-mine-reactions {
  padding: 10px;
  transform: translateX(-170px) translateY(-80px);
}

.comment-photo-container-mine {
  display: none;
}

.comment-photo-container-mine img {
  display: flex;
}

.botton-info-container {
  font-weight: 300;
  font-size: 12px;
  color: #652d52;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 10px;
  padding-right: 10px;
  position: relative;
  border: #eeeeee 1px solid;
  background-color: white;
  border-radius: 0 8px 8px 8px;
}

.botton-info-container-mine {
  font-weight: 300;
  font-size: 12px;
  max-width: -moz-fit-content;
  padding: 10px;
  padding-left: 10px;
  position: relative;
  border: #eeeeee 1px solid;
  background-color: #652d52;
  color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.botton-info-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 15px 10px 0;
  border-color: transparent white transparent transparent;
}

.botton-info-container-mine::after {
  content: "";
  position: absolute;
  top: 0;
  right: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 15px;
  border-color: transparent transparent transparent #652d52;
}

.top-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  min-width: fit-content;
}

.top-info-container-mine {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  min-width: fit-content;
  justify-content: flex-end;
}

.status-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 6px;
}

.status-info-mine {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 8px;
  justify-content: flex-end;
}

.message-info-detail {
  margin-top: 6px;
}

.message-info-detail-mine {
  margin-top: 6px;
  justify-content: flex-end;
  display: none;
  flex-direction: column;
}

.message-info-detail-mine span {
  color: white;
}

.active {
  display: flex;
}

.background-image-class {
  background-image: url("../../assets/icons/png/bg-chat.png");
}

.background-image-class::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1
}

.message-container {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 40%;
}

.send-message-button {
  background-color: #fd941b;
  color: white;
  display: flex;
  border: none !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  border-radius: 6px;
  margin-right: 1%;
}

.send-message-container {
  height: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addSquare {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  width: 65px;
  background-color: transparent;
  border-radius: 10px;
  border: 2px dashed #4a2433;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multimedia-buttons {
  display: flex;
  width: 70%;
  padding: 10px;
}

.micro-icon:hover {
  cursor: pointer;
}

.stop-icon {
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #652d5220;
}

.stop-icon:hover {
  cursor: pointer;
}

.micro-icon img {
  height: 55px;
}

.stop-icon img {
  height: 30px;
}

.audio-player {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.audio-recorder {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  background-color: #eae5ea80;
  border-radius: 4px;
}

.audio-recorder audio {
  width: 100%;
  outline: none;
  max-width: 320px;
}

.audio-player audio {
  width: 100%;
  outline: none;
}

.audio-player .play-button,
.audio-player .pause-button {
  background-color: #4caf50;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.audio-player .play-button:hover,
.audio-player .pause-button:hover {
  background-color: #45a049;
}

.audio-player .play-button::before,
.audio-player .pause-button::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.audio-player .play-button::before {
  border-right: 12px solid #ffffff;
}

.audio-player .pause-button::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 16px;
  background-color: #ffffff;
}

.audio-player .play-button:focus,
.audio-player .pause-button:focus {
  outline: none;
}

.audio-player audio::-webkit-media-controls-enclosure,
.audio-player audio::-webkit-media-controls-overflow-button,
.audio-player audio::-webkit-media-controls-panel::after {
  display: none !important;
}

.sound-wave {
  width: 96%;
  padding: 10px;
  border-radius: 18px;
}

.image-message {
  width: 100%;
  height: 100%;
}

.image-message:hover {
  cursor: pointer;
}

.image-message img {
  width: 100%;
  max-height: 260px;
  object-fit: cover;
  margin-bottom: 2px;
}

.image-message-container {
  display: grid;
  grid-gap: 10px;
}

/* Estilos para el reproductor de audio */
audio {
  /* Cambiar el color del fondo */
  background-color: #f2f2f2;
  /* Cambiar el color del texto */
  color: #333;
  /* Cambiar la fuente */
  font-family: Arial, sans-serif;
  /* Añadir espacio interno */
  /* Cambiar el tamaño del reproductor de audio */
  max-width: 180px;

  border-radius: 6px;
}

@media (min-width: 768px) {
  audio {
    min-width: 100px;
    padding: 10px;
    max-width: 250px;
  }
}

/* Estilos para los controles de reproducción */
audio::-webkit-media-controls {
  /* Cambiar el color de fondo de los controles */
  background-color: #f2f2f2;
  /* Cambiar el color de los botones */
  color: #333;
}

/* Estilos para los botones de reproducción */
audio::-webkit-media-controls-play-button {
  /* Cambiar el color del botón de reproducción */
  color: #333;
}

audio::-webkit-media-controls-volume-slider {
  /* Cambiar el color de la barra de volumen */
  background-color: #f5f5f5;
}

audio::-webkit-media-controls-volume-slider::-webkit-slider-thumb {
  /* Cambiar el color del control deslizante de volumen */
  background-color: #333;
}

/* Estilos para la barra de progreso */
audio::-webkit-media-controls-timeline {
  /* Cambiar el color de fondo de la barra de progreso */
  background-color: #f5f5f5;
}

audio::-webkit-media-controls-timeline::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-timeline::-webkit-media-controls-time-remaining-display {
  /* Cambiar el color del tiempo de reproducción */
  color: #333;
}

.dano-foto {
  margin-left: 5%;
}

.text-container {
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
  height: auto;
  overflow-wrap: break-word;
}

.time-line-ctnr .time-line > li > .time-line-item {
  background: none !important;
}

@media (max-width: 768px) {
  .time-line-ctnr .time-line > li > .time-line-item .time-line-header {
    background: #e9e9e900 !important;
  }
}

.container-header-icons-sp {
  display: flex;
  justify-content: space-around;
  width: 70px;
}

.entry-hidden {
  margin-left: 10px;
  cursor: pointer;
}

.entry-hidden img {
  height: 25px;
}

.entry-hidden img:active {
  scale: 0.9;
}

.delete-icon-entry-sp {
  width: 25px;
  cursor: pointer;
}

.delete-icon-entry-sp:active {
  scale: 0.9;
}

@import "../../../style-app/predefined.css";

.services-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.options-services-container {
  width: 90%;
  margin: auto;
  overflow-y: scroll;
  /*height: var(--v-services);*/
  height: calc(100% - 17vh);
}

.sd-button-container {
  width: 90%;
  margin: 0 auto 2vh auto;
  text-align: center;
}

.sd-dano-foto {
  width: 90%;
  margin: 0 auto 2vh auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.payment-feedback {
  width: 90%;
  margin: auto;
  align-items: center;
  text-align: center;
  color: #3e1e2b;
  font-size: 14px;
}

.ag-sp-image {
  border-radius: 6px;
  max-width: 110px;
  max-height: 110px;
  width: 40%;
  min-width: 80px;
}

.ag-icon-user {
  filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg)
    brightness(87%) contrast(88%);
  border-radius: none;
  max-height: 110px;
  min-width: 80px;
}

.new-service-info-sp {
  text-align: center;
  color: #3e1e2b;
  font-size: 14px;
}

.new-service-info-sp span {
  font-weight: 600;
}

.sp-type span {
  font-weight: bold;
  font-size: 15px;
}

.sp-margin-botton {
  margin-bottom: 10px;
}

.text-margin {
  margin-left: 5%;
}

.text-flex {
  flex-direction: column;
}

.payment-methods-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.payment-method {
  max-width: 25%;
  margin: 20px 5%;
  max-height: 50px;
  min-width: 20%;
}

.pse-icon {
  min-width: auto;
}

.update-service-details .acceso-cubierta-info {
  width: 80%;
  margin: auto;
}

.bigger-img {
  max-width: 40%;
}

@media (min-width: 768px) {
  .appbar-no-background .services-appbar-container .appbar {
    background-color: white;
    color: #652d52;
    display: flex !important;
    height: 10vh;
    box-shadow: 0 4px 2px 1px #f8f7f8;
    border-radius: 0;
    padding-bottom: 5px;
  }

  .appbar-no-background .services-user .appbar {
    box-shadow: none;
  }

  .options-menu-container {
    padding: 0;
    width: 100%;
    grid-template-columns: auto auto auto auto;
    height: fit-content;
    overflow-y: hidden;
  }

  .menu-navbar-container {
    display: none;
  }

  .servicesInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .si-container-info {
    position: relative;
    width: 33%;
    margin: 4% auto 2vh auto;
  }

  .options-services-container {
    height: 78vh;
    overflow-x: hidden;
  }

  .menuOption {
    flex-grow: initial;
    width: 12%;
    min-width: 100px;
  }

  .servicesMenu {
    box-shadow: 0 4px 2px 1px #f8f7f8;
    border-bottom: none;
  }
}

@media (min-width: 1200px) {
  .si-container-info {
    position: relative;
    width: 24%;
    padding: 3vh 2%;
  }
}

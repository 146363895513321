.containerIconDropdown {
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 45px;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    align-items: center;
    min-height: 30px;
    width: 100%;
}

.containerDroppdown {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconDropdown {
    width: 75%;
}

.iconDropdownInput {
    width: 50px !important;
}

.baseDropdown {
    background-color: transparent;
    border: none;
    outline: none;
    height: 95%;
    width: 75%;
    color: #ABAAAA;
    font-size: 13px;
    font-weight: normal;
    min-width: 43px;
    cursor: pointer;
}

.dropdownWithInputContainer {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
    align-items: center;
    min-height: 30px;
    width: 100%;
}

.containerDropdownInput {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    height: 45px;
}

.dwi-input {
    background-color: #fff;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    height: 45px;
}



.rol-option {
    border: none;
}

.locationDropdown {
    width: 100%;
}
.containerValues {
  min-width: 100px;
  width: 50vw;
  display: grid;
  grid-template-columns: 80%;
  background-color: #652d52;
  color: #cccccc;
  text-align: center;
  border-radius: 7px;
  bottom: 105px;
  right: 60px;
  visibility: hidden;
  position: fixed;
  z-index: 1;
  justify-content: center;
  line-height: 2;
  font-size: 16px;
}

.show {
  visibility: visible;
}

.popupItems {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2vh 0 2vh 0;
  cursor: pointer;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
}

.popupItems:hover {
  opacity: 70%;
}
.borderTopWhite {
  border-top: 1px solid #cccccc;
}

.bottomTriangle {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #652d52;
  transform: translateY(-50%);
  position: absolute;
  left: 94%;
  bottom: -9%;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg); /* IE 9 */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari and Chrome */
  -o-transform: rotate(45deg); /* Opera */
}

.popup {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.containerPopup {
  border-radius: 20px;
  min-width: 200px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vh 0 3vh 0;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #3e1e2b;
}

.buttonsContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  justify-items: center;
  padding-top: 3vh;
}

.oneButtonContainer {
  display: grid;
  width: 100%;
  grid-template-columns: auto;
  justify-items: center;
  padding-top: 3vh;
}

.iconPopup {
  width: 25%;
  padding-bottom: 3vh;
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg)
    brightness(98%) contrast(88%);
  max-width: 85px;
}

.textButtonPopup {
  text-align: center;
}

.buttonIconPopup {
  width: 70%;
  padding-top: 3vh;
  justify-items: center;
  display: grid;
}

.newButtonIconPopup {
  width: 100%;
  padding-top: 3vh;
  justify-items: center;
  display: grid;
}

.icon-popup-text {
  text-align: center;
}

.icon-popup-text-yellow {
  background-color: #ff8f1c;
  text-align: center;
}

.spp-container {
  display: flex;
  flex-direction: column;
  max-width: 75vw;
  max-height: 75vh;
}

.search-bar-container {
  width: 100%;
  margin-bottom: 5%;
}

.spp-info-container {
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  color: #652d52;
}

.spp-info-icon {
  width: 10%;
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg)
    brightness(98%) contrast(88%);
  max-width: 30px;
}

.spp-info-text {
  align-self: center;
}

.new-property-button {
  width: 90%;
  align-self: center;
  margin-top: 5%;
}

.properties-container {
  width: 100%;
  max-height: 30vh;
  overflow-y: scroll;
}

.info-prop-container {
  margin-top: 5%;
  color: #652d52;
}

.prop-type {
  font-weight: bold;
  /*
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    */
}

.loader-container {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #5f5f5f;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.btn-negative {
  background: #f0eaee;
  border: 1px solid #652d52;
  color: #652d52;
}

.btn-positive {
  background: #652d52;
  color: #ffffff;
}

.popup-select-items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.psi-icon-container {
  width: 30%;
  margin: 0 auto 2vh auto;
  display: flex;
  justify-content: center;
  align-content: center;
}

.psi-icon {
  width: 80%;
  margin: 0;
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg)
    brightness(98%) contrast(88%);
  max-height: 55px;
}

.psi-items-container {
  max-height: 30vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2vh 0;
}

.psi-item {
  width: 87%;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  padding: 10px 0 10px 10%;
  margin: 10px auto;
  color: #cbcbcb;
  font-size: 14px;
  min-height: fit-content;
  cursor: pointer;
}

.psi-text {
  font-size: 14px;
  color: #3e1e2b;
  text-align: center;
  margin-bottom: 2vh;
}

.psi-button {
  width: 100%;
  margin: auto;
  border-radius: 5px;
  background-color: #cbcbcb;
  display: flex;
  padding: 10px 0 10px 5px;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.psi-button-active {
  background: #652d52;
  color: #ffffff;
}

.checkbox-item {
  font-size: 18px;
  margin-bottom: 20px;
  color: #652d52;
  display: flex;
  align-items: center;
}

.admin-filter-input {
  margin-left: 5px;
}

.selectBoxContainer {
  background: #eae2e7;
  border-radius: 6px;
  padding: 5px;
}

.checkbox-box {
  border: 0.5px solid #b296a8;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  min-width: 12px;
}

.selectBoxActive {
  background-color: #652d52;
}

.admin-filter-input {
  margin-left: 5px;
}

.reasons-title {
  font-size: 14px;
  font-weight: bold;
  color: #4a2433;
  margin-bottom: 2vh;
}

.prc-buttons-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 2vh;
}

.prc-button {
  width: 40%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  height: 40px;
  font-size: 14px;
}

.prc-button-cancel {
  background: #f0eaee;
  border: 1px solid #652d52;
  color: #652d52;
}

.prc-button-send {
  background: #652d52;
  color: #f0eaee;
}

.textarea-reject-cotizacion {
  background-color: transparent;
  width: 96%;
  resize: none;
  min-width: 100px;
  height: 10vh;
  align-items: center;
  min-height: 50px;
  outline: none;
  border: none;
  border-radius: 13px;
  padding: 3% 0 0 3%;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #4a2433;
}

.psi-item-selected {
  background-color: #e5e5e5;
  color: #652d52;
}

.prc-reasons-container {
  height: 30vh;
  overflow-y: scroll;
}

.container-values-seguimiento {
  min-width: 100px;
  width: 50vw;
  /*display:grid;
    grid-template-columns: 80%;*/
  background-color: #fd941b;
  color: white;
  text-align: center;
  border-radius: 7px;
  bottom: 123px;
  right: 61px;
  visibility: hidden;
  position: fixed;
  z-index: 1;
  justify-content: center;
  line-height: 2;
  font-size: 15px;

  height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 200px;
}

.seguimiento-bottom-triangle {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fd941b;
  transform: translateY(-50%);
  position: absolute;
  left: 93%;
  bottom: -3%;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg); /* IE 9 */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari and Chrome */
  -o-transform: rotate(45deg); /* Opera */
}

.ceteredIcon {
  padding: 0;
  margin: auto;
  width: 20%;
}

.purple-text {
  font-weight: 300;
  font-size: 12px;
  color: #3e1e2b;
  margin-bottom: 5px;
}

.yellow-text {
  font-weight: 300;
  font-size: 12px;
  color: #ff8f1c;
  margin-bottom: 15px;
}

.popup-fp-title {
  font-weight: bold;
  color: #3e1e2b;
  margin-bottom: 10px;
}

/*ADMIN NOTIFICATIONS*/

.portal {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 4;
}

.hide {
  display: none;
}

.showNoti {
  display: block;
  width: 30vw;
  height: 60vh;
  background-color: white;
  border-radius: 16px;
  top: 10vh;
  position: fixed;
  right: 4vw;
  max-width: 460px;
}

.ap-header-container {
  height: 15%;
  border-bottom: 1px solid #ede6ea;
  display: flex;
  align-items: center;
  padding: 0vh 3% 0 3%;
  position: relative;
}

.ap-icon-container {
  width: 20%;
  display: flex;
  justify-content: center;
  position: relative;
}

.ap-title {
  width: 94%;
  text-align: center;
  position: absolute;
  color: #652d52;
  font-weight: bold;
  font-size: 18px;
}

.ap-icon {
  width: 40%;
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg)
    brightness(98%) contrast(88%);
  max-width: 28px;
}

.ap-body-container {
  height: 83%;
  display: flex;
  flex-direction: column;
  padding: 0vh 1.5% 0 1.5%;
  overflow-y: hidden;
}

/* END ADMIN NOTIFICATIONS*/

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .popupItems {
    padding: 1vh 0 1vh 0;
  }
}

@media (min-width: 1400px) {
  .show {
    width: 20vw;
  }
}

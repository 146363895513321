.new-advance-detail {
  display: grid;
  padding: 12px;
  height: 100dvh;
  box-sizing: border-box;
  align-content: baseline;
  grid-template-rows: auto 1fr auto;

  & .service-details {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;

    & :is(.service-client-info-skeleton) {
      width: 100%;
    }

    & .first-section {
      display: flex;
      gap: 18px;

      & .service-client-info {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;

        & .client-info {
          font-weight: bold;
          word-break: break-word;
          font-size: 18px;
          -webkit-text-stroke: 0.5px currentColor;
          letter-spacing: 0.5px;
          line-height: 1.1;

          & .id-tag {
            color: #652d52;
            font-size: 14px;
          }
        }

        & .service-status {
          font-size: 12px;
          padding: 4px 12px;
          border-radius: 12px;
          color: #fff;
          line-height: normal;
          width: fit-content;
          white-space: nowrap;
          align-self: self-start;
        }
      }
    }

    & :is(.service-progress-info-skeleton) {
      width: 100%;
      height: 150px;
    }

    & .service-progress-info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 6px;
      background-color: color-mix(in srgb, transparent 95%, #652d52);
      border-radius: 8px;

      & .money-detail {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 8px;
        padding: 8px 10px;

        &>div {
          display: flex;
          gap: 6px;
          align-items: center;

          &>svg {
            height: 24px;
            width: 24px;
            background-color: color-mix(in srgb, transparent 80%, #652d52);
            border-radius: 6px;
            padding: 6px;
            margin-right: 2px;
            color: color-mix(in srgb, transparent 5%, #652d52);
          }

          &>footer {
            display: flex;
            column-gap: 12px;
            row-gap: 4px;
            flex-wrap: wrap;
            flex-direction: column;

            &>span {
              &:first-child {
                font-weight: bold;
                font-size: 12px;
              }

              &:last-child {
                color: red;
                font-size: 11px;

                &::before {
                  content: "-";
                }
              }
            }
          }
        }
      }

      & .phases-status {
        display: flex;
        gap: 18px;
        background-color: #fff;
        padding: 12px;
        border-radius: 8px;

        & .progress-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          & .progress-format {
            font-size: 12px;
            margin: 0px;
          }
        }
      }

      & .categories-status {
        display: flex;
        gap: 16px;
        overflow-x: auto;
        padding: 12px;
        background-color: #fff;
        border-radius: 8px;
        justify-content: space-between;
        scrollbar-width: thin;

        & .status-container {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;

          & .container-title {
            margin: 0px;
            font-size: 10px;
            text-align: center;
            color: #652d52;
            white-space: nowrap;
            overflow: hidden;
            width: 50px;
            text-overflow: ellipsis;
          }

          & .progress-container {
            display: flex;
            flex-direction: column;
            width: fit-content;
            gap: 4px;
            margin-inline: auto;
            overflow: visible;
            align-items: center;

            &>svg {
              height: 18px;
              width: 18px;
              width: fit-content;
            }

            &>span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  & .timeline-advance {
    font-size: 14px;
    margin-block: 18px 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & :is(.timeline-advance-title-skeleton) {
      width: 100%;
      margin-bottom: 4px;
    }

    & h1,
    h,
    p {
      margin: 0px;
    }

    &>h1 {
      font-size: 18px;
      background-color: #fff;
      word-break: break-word;
    }

    & :is(.timeline-container-skeleton) {
      width: 100%;
      height: 100%;
    }

    & .timeline-container {
      margin-top: 12px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      scrollbar-width: thin;

      & .ant-timeline-item {
        padding: 0px;
      }

      & .timeline-item {
        position: relative;

        & .timeline-item-header {
          position: sticky;
          top: 0px;
          z-index: 1;
          display: block;
          border: 1px solid color-mix(in srgb, transparent 40%, #652d52);
          background-color: color-mix(in srgb, #fff 45%, #652d52);
          color: #fff;
          padding: 4px 8px;
          border-radius: 4px;
          font-weight: bold;
          -webkit-text-stroke: 0.2px color-mix(in srgb, transparent 60%, #652d52);

          &>h1 {
            font-size: 15px;
          }

          &>time {
            font-size: 12px;
            font-weight: 600;
          }
        }

        & .ant-timeline {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &:has(.ant-timeline-item) {
            margin-top: 28px;
          }
        }

        & .timeline-sub-item {
          display: flex;
          box-sizing: border-box;
          transition: 0.1s border ease;
          border-radius: 8px;
          padding: 8px 12px;
          cursor: pointer;
          border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
          color: black;
          align-items: center;

          &:hover,
          &:active {
            border-color: #652d52;
          }

          &>svg {
            height: 32px;
            width: 32px;
            margin-inline: 0px 18px;
          }

          & .sub-item-content {
            & .sub-item-desc {
              font-size: 13.5px;
              line-height: 1.1;
            }

            &>footer {
              &>p {
                text-transform: lowercase;

                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    & .ant-timeline-item-content {
      font-size: 12px;
    }
  }

  & .nav-bar {
    align-self: self-end;
  }
}
.baseContainer {
  width: 100%;
  line-height: 1.5;
  color: #3e1e2b;
}

.spaceBetweenContainers {
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.trashIcon {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20px;
  z-index: 999;
  min-width: 15px;
  right: 35px;
  top: 30%;
}

.trashIcon:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.SpSpaceBetweenContainers {
  margin-bottom: 2vh;
  translate: translateX(-20px);
}

.SpSpaceBetweenContainers:focus,
.SpSpaceBetweenContainers:active,
.SpSpaceBetweenContainers:hover {
  background-color: #d1cfcd;
  margin-bottom: 2vh;
  transition: background-color 0.3s ease;
  transform: translateX(10px);
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spTitleStyle {
  width: 85vw;
}

.servicesContainer {
  display: grid;
  grid-template-columns: 70% 30%;
  width: 100%;
  height: 100%;
}

.headerContent {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  gap: 15px;
  color: black;
  box-shadow: none;
}

.contentContainer {
  font-size: 14px;
}

.infoContainer {
  font-size: 13px;
  cursor: pointer;
}

.Collapse_DataClass {
  border: none;
  box-shadow: none;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
}

.textOverflow {
  text-overflow: ellipsis;
}

.buttonsContanier {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.categorieContainer {
  display: grid;
  grid-template-columns: 20% 70% 10%;
  width: 100%;
}

.read {
  background-color: #fff;
}

.iconsContanier {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.rightArrowIcon {
  max-width: 35px;
  min-width: 20px;
  width: 100%;
  filter: invert(10%) sepia(14%) saturate(2411%) hue-rotate(284deg)
    brightness(102%) contrast(89%);
}

.circularDiv {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #fd941b;
}

.jp-sub-picture {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 40px;
  margin-top: 40px;
  right: 0;
}

.notification-picture-container-non-read {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #fd941b;
}

.notification-picture-container {
  border: solid 2px #652d52;
  display: flex;
  justify-content: flex-start;
  margin-right: 15px;
  align-items: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.circleContanier {
  display: flex;
  justify-content: flex-start;
  margin-right: 15px;
  align-items: center;
}

.timeColor {
  color: #cccc;
}

.purpleCircle {
  background-color: #652d52;
}

.yellowCircle {
  background-color: #fd941b;
}

.force-idon-rfp {
  margin-left: 24px;
  width: 16px !important;
  height: 16px !important;
}

.infoDate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: smaller;
}
.infoPersonal {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.infoPersonal {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sp-infoPersonal,
.sp-infoDate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #cccc;
}

.sp-infoGreyText {
  max-width: 50%;
  color: #3e1e2b;
}

.ps-categorieContainer {
  display: grid;
  grid-template-columns: 95%;
  padding: 2%;
}

.spaceBetweenPsNotifications {
  padding-bottom: 1vh;
  color: #3e1e2b;
}

.noty-empty {
  background-color: white;
  padding-bottom: 1vh;
  color: #3e1e2b;
}

.notification-container-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 70%;
  cursor: pointer;
}

.header-message-info {
  font-size: 13px;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.message-info {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.options-noti {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10%;
}

.options-selector {
  font-size: 26px;
}

.options-selector:hover {
  background-color: #eae2e7;
  border-radius: 6px;
  cursor: pointer;
}

.options-noti p {
  font-size: 12px;
}

.new-message-noti {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fd941b;
}

.notification-container-box {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.infoTime {
  display: flex;
  justify-self: end;
  align-items: center;
  color: #c1abba;
  font-size: 12px;
}

.infoTimeNow {
  color: #fd941b;
}

.infoNowContainer {
  background: #ffffff;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.search-content-container {
  display: flex;
  line-height: 1.5;
}

.search-container-icon {
  filter: invert(10%) sepia(14%) saturate(2411%) hue-rotate(284deg)
    brightness(102%) contrast(89%);
  width: 100%;
  max-width: 20px;
  min-width: 15px;
}

.sc-icon-container {
  width: 7%;
  min-width: 7%;
  margin-right: 2vw;
  display: flex;
  align-items: end;
}

.sc-container {
  width: 80%;
  display: grid;
  grid-template-columns: 69% 29%;
  grid-column-gap: 2%;
  column-gap: 2%;
  background: #ffffff;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 3vh 5%;
  margin: 4% auto 2% auto;
}

.sc-container-search {
  width: fit-content;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 3vh 5%;
  margin: 4% auto 2% auto;
  max-height: 200px;
}

.si-container-info {
  width: 88%;
  position: relative;
  display: grid;
  background: #ffffff;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 3vh 5%;
  margin: 4% 0 2% 6%;
}

.sc-space-style {
  line-height: 1.2;
}

.service-search-entries {
  color: #cccc;
  margin-bottom: 5px;
}

.sc-label-icon {
  width: 100%;
  filter: invert(100%);
}

.sc-text-label {
  color: white;
  align-self: center;
}

.state-requested {
  background-color: #652d52;
}

.state-in-design {
  background-color: #ff69b4;
}

.state-to-be-approved {
  background-color: #de8080;
}

.state-approved {
  background-color: #7dbd07;
}

.state-rejected {
  background-color: #db3b37;
}

.state-reschedule {
  background-color: #484244;
}

.state-in-progress {
  background-color: #03c1e1;
}

.state-done {
  background-color: #fd941b;
}

.state-warranty {
  background-color: #cc7951;
}

.state-quotation-progress {
  background-color: #fecd00;
}

.state-ready-execute {
  background-color: #3a4d8f;
}

.state-pause {
  background-color: #838b95;
}

.state-waiting-payment {
  background-color: #61903e;
}

.state-all-services {
  background-color: #fabc2d;
}

.state-cancel {
  background-color: #ff9d76;
}

.sc-label-general {
  width: fit-content;
  border-radius: 5px;
  padding: 0.5vh 2vw;
  margin-bottom: 2vh;
}

.sc-icon-container-label {
  width: 15%;
  margin-right: 2vw;
  display: flex;
  align-items: center;
}

.assistants-container {
  width: 80%;
  display: grid;
  grid-template-columns: 25% 73%;
  grid-column-gap: 2%;
  column-gap: 2%;
  background: #ffffff;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 3vh 5%;
  margin: 4% auto 2% auto;
  cursor: pointer;
}

.photo-assistant-container {
  display: flex;
  align-items: center;
  /*justify-content: center;*/
  width: 100%;
}

.photo-assistant {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.assistants-container .title {
  font-size: 14px;
  max-width: 100%;
}

.assistants-container .sc-icon-container {
  align-items: center;
}

.selected-assistant {
  border: 1px solid #fd941b;
}

.service-flex-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.service-container-hour {
  padding-top: 0.5vh;
  font-weight: 300;
  font-size: 12px;
  color: #3e1e2b;
  text-align: end;
}

.img-center-border-radius {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.ps-goals-container {
  display: flex;
  flex-direction: column;
  width: calc(90% - 20px);
  margin: 0 auto 2vh auto;
  background: #ffffff;
  box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 60px;
  justify-content: center;
  padding: 10px 20px;
}

.top-container {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.top-container .inner {
  display: flex;
}

.vertical_dots {
  max-width: 20px;
  filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg)
    brightness(87%) contrast(88%);
}

.goal-number-container {
  background-color: #652d52;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  align-self: center;
}

.goal-info-container {
  display: flex;
  flex-direction: column;
}

.gi-name-container {
  display: flex;
  align-items: center;
}

.gi-name {
  font-weight: bold;
  font-size: 14px;
  color: #3e1e2b;
  margin-bottom: 3px;
}

.gi-end-date,
.goal-value {
  font-weight: 300;
  font-size: 10px;
  color: #3e1e2b;
}

.goal-icon {
  filter: invert(66%) sepia(95%) saturate(2292%) hue-rotate(347deg)
    brightness(103%) contrast(98%);
  width: 17px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.document-alert-icon {
  width: 13px;
}

.goal-bar {
  width: 100%;
  height: 5px;
  background-color: #e9ecf4;
  border-radius: 8px;
}

.goal-progress-bar {
  background-color: #fd941b;
  border-radius: 8px;
  height: 100%;
}

.values-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
}

.gi-end-date span {
  font-weight: bold;
}

.documents-container {
  width: 100%;
  display: flex;
}

.ps-documents-container {
  padding: 0 10px;
}

.document-icon-container {
  height: 40px;
  width: 40px;
  margin-right: 5px;
}

.documents-info-container {
  height: 40px;
  justify-content: space-around;
  width: calc(100% - 40px);
}

.document-bottom-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.documents-icons {
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg)
    brightness(98%) contrast(88%);
  width: 30%;
}

.documents-icons:first-child {
  margin-right: 10px;
}

.documents-icons-container {
  display: flex;
  justify-content: flex-end;
}

.document-icon {
  filter: invert(100%);
  width: 55%;
}

.management-icon-filter {
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg)
    brightness(98%) contrast(88%);
}

@media (min-width: 768px) {
  .sc-text-label {
    min-width: max-content;
  }

  .sc-icon-container-label {
    min-width: 15px;
  }
}

.progress-container {
  width: 100%;
  margin-top: 8%;
  border-radius: 10px;
  overflow: hidden;
}

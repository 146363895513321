.container-base-template{
    width:90%;
    height: 100%;
    display:flex;
    flex-direction: column;
    margin: auto;
    
}

.container-image-usage{
    width: 100%;
    /*height: 55%;*/
    min-height: 40vh;
    display: flex;
    align-items: flex-end;
}

.image-usage{
    width: 100%;
    /*height:100%*/
}

.container-title-usage{
    display: flex;
    align-items: center;
    color:#652D52;
    font-size: 18px;
    font-weight: bold;
    height: 15%;
}

.container-text-usage{
    width: 100%;
    display: flex;
    color:#652D52;
    font-size: 13px;
    height: 20%;
}

.container-btn-usage{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4vh;
    justify-content: center;
}

.container-step-usage{
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    
}

@media (min-width:768px){
    .container-image-usage{
        min-height: 30vh;
    }
}







.basic-info-item-container{
    display: flex;
    align-items: center;
    margin: 10px 0 5px 11%;
}

.ag-icon-container-info{
    display: flex;
    justify-content: center;
    width: 15px;
    padding: 5px 3px;
    background-color: #652D52;
    border-radius: 6px;
    margin-right: 5px;
    box-shadow: 0px 2.13673px 10px rgba(101, 45, 82, 0.6)
}

.ag-icon-info{
    /*width: 70%;*/
    width: 80%;
    filter: invert(100%);
}


.basic-info-text{
    font-size: 12px;
    color: #652D52;
    max-width: 80%;
    overflow-wrap: break-word;
}
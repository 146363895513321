.mediaContent{
    background-color: #652D52;
    width: 65px;
    height: 65px;
    min-height: 65px;
    border-radius: 10px;
    object-fit: cover;

}

.bigMedia{
    background-color: #652D52;
    width: 200px;
    height: 200px;
    min-height: 65px;
    border-radius: 10px;
    object-fit: cover;

}

.location-form-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
}

.contact-container .location-form-container{
    margin-bottom: 2vh;
}

.location-form-field{
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 45px;
    box-shadow: 0px 3.5px 7px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    align-items: center;
    min-height: 30px;
    padding: 0 5%;
    width: 38%;
    margin-bottom: 2vh;
}

.full-width-field{
    width: 90%;
}

.third-width-field{
    width: 20%;
    min-width: 50px;
}

.location-form-input{
    width: 90%;
    height: 80%;
    padding-left: 5%;
}

@media(min-width:768px){

    .location-form-field{
        min-width: 80px;
    }

    .third-width-field{
        width: 20%;
        min-width: 70px;
    }
}
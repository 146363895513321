.new-bitacle-view {
  --separation: 6px;
  --border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
  --border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  box-sizing: border-box;

  & h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0px;
  }
}
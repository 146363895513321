#root {
  display: flex;
  flex-direction: column;

  & .bitacle-phase-view {
    --btn-color: gray;
    --separation: 6px;
    --border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
    --border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 32px;
    padding: 12px;
    box-sizing: border-box;

    & h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0px;
    }

    & .phases-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 100%;

      & .section-title {
        font-size: 24px;
        text-align: center;
        color: color-mix(in srgb, transparent 20%, #652d52);
      }

      & .phases-options {
        display: flex !important;
        flex-direction: column;
        gap: 12px;
        height: 100%;

        & .phase-option {
          font-size: 28px;
          text-decoration: none;
          font-weight: bold;
          text-align: center;
          border: 12px;
          background-color: var(--btn-color);
          color: color-mix(in srgb, gray 80%, #652d52);
          box-shadow: none;
          height: auto;
          width: auto;
          flex: 1;

          &:is([data-phase="0"]) {
            --btn-color: #f8bbd0;
          }

          &:is([data-phase="1"]) {
            --btn-color: #d1c4e9;
          }

          &:is([data-phase="2"]) {
            --btn-color: #cfd8dc;
          }

          &:is([data-phase="3"]) {
            --btn-color: #ffd600;
          }
        }
      }
    }
  }
}
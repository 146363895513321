.media-section-body {
  --separation: 12px;
  --border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
  --border-radius: 12px;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden !important;

  & .ant-tabs {
    overflow: auto;

    & .ant-tabs-nav {
      position: sticky;
      top: 0px;
      background-color: #fff;
      margin: 0px;
      z-index: 1;
    }

    & .ant-tabs-content-holder {
      & .ant-tabs-content {
        & .ant-tabs-tabpane {
          & .media-all-list {
            padding: var(--separation);
          }
        }
      }
    }
  }
}
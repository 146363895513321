.lock-entry-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.7); */
    position: absolute;
    /* backdrop-filter: blur(1px); */
    top: 0;
    left: 0;
    z-index: 9999;
}

.lock-entry-icon {
    width: 20px;
    top: 10%;
    right: 10%;
    display: flex;
    position: absolute;
    height: auto;
    filter: drop-shadow(0 0 5px red);
}
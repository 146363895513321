.timeline-resume {
  max-height: 100%;
  overflow-y: auto;
  margin: 10px;
}

.time-line-content-resume {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.header-time-line-content-resume {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 5px;
  margin-left: 7%;
  transform: translateX(-20px);
  transition: transform 0.3s ease;
}

.togle-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.time-line-header-content-resume {
  display: flex;
  min-width: 320px;
}

@media (max-width: 768px) {
  .header-time-line-content-resume {
    font-size: 14px;
    overflow: hidden;
    margin-left: -1.3px;
    white-space: normal !important;
    transform: translateX(0) !important;
  }

  .title-time-line-resume {
    white-space: normal !important;
  }

}

.time-line-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-time-line-resume {
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 6%;
  color: #652D52;
}


@media (max-width: 400px) {
  .timeline-resume {}

}

.content {
  display: flex;
  align-items: end;
}

.days-content {
  transform: translateY(-10px);
  margin-left: 5px;
  font-size: 12px;
  color: gray;
}

.dot {
  display: flex;
  width: 100%;
}

.dot span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: #3e1e2b0b 0px 0px 0px 2px;
}

.line {
  height: 100%;
  min-height: 40px;
  width: 1.5px;
  margin-left: 4.5%;
}

.line-red {
  background-color: red;
}

.line-green {
  background-color: rgb(23, 191, 23);
}

.line-gray {
  background-color: rgb(158, 158, 158);
}


.backgroun-green {
  background-color: rgb(23, 191, 23);
}

.border-gray {
  border: 2px solid rgb(237, 237, 237);
}

.backgroun-yellow {
  background-color: yellow;
}

.border-green {
  border: 2px solid rgb(23, 191, 23);
}

.border-red {
  border: 2px solid red;
}

.border-yellow {
  border: 2px solid yellow;
}


.content-late {
  font-size: 14px;
  font-weight: 500;
  color: rgb(233, 80, 80);
  width: 30%;
  font-size: 12;
}

.content-time {
  font-size: 14px;
  font-weight: 500;
  width: 30%;
  color: #4ebd4e
}

.resume-actual-phase-title {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resume-actual-phase-title h3 {
  font-size: 18px;
  font-weight: 500;
  color: orange;
}

.resume-actual-phase-title p {
  font-size: 18px;
  font-weight: 500;
  margin-left: 4px;
  color: orange;
}

.span-opcional {
  font-size: 12px;
  font-weight: 600;
  color: #652D52;

}

.space-timeline {
  height: 10px;
}

.togle-activity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.togle-activity-title {
  margin-bottom: 15px;
  text-align: center;
}

.togle-activity-title:hover {
  cursor: pointer;
}


.togle-activity-title h3 {
  font-size: 18px;
  font-weight: 600;
  color: #652D52;
  margin-bottom: 5%;
  opacity: 1;
  transition: opacity 2s ease;
}

.togle-activity-title h3:hover {
  opacity: 30%;
}

.togle-activity-title h3:active {
  opacity: 20%;
}
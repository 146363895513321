@import '../../style-app//predefined.css';

*{
    margin: 0;
    padding: 0;
}

#login-container{
    height: 15vh;
    width: 100%;
    min-height: 50px;
}

#body-login{
    width: 100%;
    max-width:480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: var(--v-auth);
    min-height: 350px;
}

#appbar-container{
    width: 100%;
    height: 100%;
}

#logo-container{
    margin-top:2.5vh;
    margin-bottom:3vh;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-content: center;
}

#logo-icon{
    width: 35%;
}

#input-container{
    width: 80%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin-bottom: 3vh;
    line-height: .5;
}

#button-container{
    width: 80%;
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    /* margin-bottom: 3.5vh; */
    min-height: 100px;
}

#social-media-container{
    width: 30%;
    height: 10%;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 3.5vh;
}

.icon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



#fb-icon{
    width:60%;
    max-width:50px;
    
}

#google-icon{
    width:50%;
    max-width:50px;
}

#register-container{
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#register-link{
    font-size: 11px;
    color:#361A26;
    font-weight: bold;
    cursor: pointer;
}

#register-text{
    font-size: 11px;
    color:#361A26;
    margin-right:5px;

}

.login-main-container{
    width: 100%;
}

.login-image-desktop{
    display: none;
}

@media(min-width:768px){
    .login-main-container{
        height: 100%;
    }

    .login-image-desktop{
        display: block;
        width: 50%;
    }

    #login-container{
        width: 50%;
        margin: auto;
        height: calc(100% - 17vh);
    }

    .login-main-container #appbar-container{
        display: none;
    }

    .login-main-container #body-login{
        height: 100%;
    }

    #social-media-container{
        margin-bottom: 0;
        height: fit-content;
    }

    /* .login-main-container #button-container{
        margin-bottom: 7vh;
    } */

    .login-main-container #input-container{
        margin-top: 13vh;
        height: fit-content;
    }


}

@media(min-width:1400px){

    /* .login-main-container #button-container{
        margin-bottom: 10vh;
    } */

}
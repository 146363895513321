.button-navbar-container{
    min-width: 300px;
    width:100%;
    display: flex;
    box-shadow: 0px -10px 10px -6px rgba(0, 0, 0, 0.1);
    height: 100%;
    justify-content: center;
    background-color: white;
    z-index: 1;
}

.buttonContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.moreContainer{
    width: 98%;
    display: flex;
    justify-content: flex-end;
    text-align: -webkit-center;
    position: relative;
    margin-right:2%;
    top: -150%;
}

.selected{
    background-color: #fd941b;
    
}

.ps-button-navbar-container{
    min-width: 300px;
    width:100%;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    box-shadow: 0px -10px 10px -6px rgba(0, 0, 0, 0.1);
    height: 100%;
    justify-content: center;
    background-color: white;
    z-index: 1;
}


@media(min-width:768px){
  .menu-desktop-main-container .whatsappButton{
    visibility: hidden;
    top:15vh;
    right: 20%;
  }

  .menu-desktop-main-container .seguimientoButton, .seguimiento-button-portal-show {
    top:15vh;
    right: 19.5%;
  }

  .menu-desktop-main-container .seguimiento-button-portal-show {
    top: 90%;
    right: 20%;
  }

  .menu-desktop-main-container .container-values-seguimiento {
    bottom: 12vh ;
    right: calc(44% - 180px);
  }
}

@media(min-width:1200px){


  .menu-desktop-main-container .seguimientoButton, .seguimiento-button-portal-show {
    right: 22.5%;
    top: 3vh;
  }

  .menu-desktop-main-container .seguimiento-button-portal-show {
    right: 30%;
  }

  .menu-desktop-main-container .container-values-seguimiento {
    right: calc(48% - 180px);
    bottom: 12vh;
  }

  .menu-desktop-main-container .whatsappButton{
    right: 22%;
    top:3vh;
  }

}

@media(min-width:1400px){


  .menu-desktop-main-container .seguimientoButton{
    right: 24.5%;
    top: 15vh;
  }

  .menu-desktop-main-container .seguimiento-button-portal-show {
    right: 32.5%;
    /*top: 15vh;*/
    top:85vh
  }

  .menu-desktop-main-container .container-values-seguimiento {
    right: calc(47% - 180px);
    bottom: 17vh;
  }

  .menu-desktop-main-container .whatsappButton{
    right: 24%;
    top:15vh;
  }
}


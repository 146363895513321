.ps-services-list-search {
    width: 90%;
    margin: 2vh auto;
}

.unexpected-info input {
    height: 40px;
    width: 60%;
    border-radius: 5px;
    border: 0px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.ps-services-list-days-container {
    width: 90%;
    margin: 2vh auto;
    display: flex;
    flex-direction: column;
    min-height: fit-content;
}

.ps-sl-days-text {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
}

.my-services-days-text {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
    justify-content: space-between;
}

.ps-sl-days-title {
    font-weight: bold;
    font-size: 18px;
    color: #3E1E2B;
}

.ps-sl-day-details {
    font-weight: 300;
    font-size: 14px;
    color: #B7B7B9;
}

.ps-sl-days-icon-container {
    display: flex;
    justify-content: center;
}

.ps-sl-days-icon {
    width: 75%;
    max-width: 25px;
    filter: invert(12%) sepia(6%) saturate(5684%) hue-rotate(284deg) brightness(91%) contrast(90%);
    cursor: pointer;
}

.ps-ls-list-container {
    width: 100%;
    margin: auto;
}

.current-day-container {
    width: 35%;
}

.details-current-day-container {
    width: 65%;
}

.services-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ps-services-card {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-request-services {
    margin-bottom: 10%;
    position: relative;
}

.cg-circle-number {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FD941B;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 1%;
    font-size: 12px;
    position: absolute;
    top: -5%;
    left: 85%
}

.rs-btn-options {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.rs-btn-container {
    width: 70%;
    display: flex;
    justify-content: center;
}

.request-details-container {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /*height: 75vh;*/
    height: calc(100% + 17vh);
    overflow-y: scroll;
}

.details-popup {
    max-height: 70vh;
    overflow-y: scroll;
}

.my-services-container {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /*height: 65vh;*/
    height: calc(100% - 17vh - 50px);
    overflow-y: scroll;
}

.seguimiento-container {
    /*justify-content: space-between;*/
    height: calc(100% - 17vh - 50px);
}

.seguimiento-completed-container {
    overflow-y: scroll;
    max-height: calc(100% - 90px);

}

.ps-services-list-container {
    height: 100%;
}

.my-requests-container {
    height: calc(100% - 17vh);
}

.ps-options-menu-container {
    padding: 7% 5% 0 5%;
    display: grid;
    width: 90%;
    /*height: var(--vh);*/
    grid-template-columns: auto auto;
    align-items: flex-start;
    /*grid-row-gap: 4%;*/
}

@media(min-width:768px) {

    .my-requests-container {
        /*overflow-y: scroll;*/
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        margin: auto;
        justify-content: space-around;
    }

    .appbar-no-background .new-service-appbar-container .appbar {
        background-color: white;
        color: #652D52;
        display: flex !important;
        height: 10vh;
        box-shadow: 0 4px 2px 1px #F8F7F8;
        border-radius: 0;
        padding-bottom: 5px;

    }

    .ps-services-list-container {
        height: calc(100% - 10vh);
    }

    .psStyleBigCard {
        width: 60%;
        max-width: 400px;
    }

    .container-request-services {
        margin-bottom: 5%;
    }

    .request-details-container {
        height: calc(100% - 11vh);

    }

    .seguimiento-container {
        height: calc(100% - 10vh);
    }

    .my-services-container {
        height: calc(100% - 12vh);
    }

    .change-services-display {
        flex-direction: row-reverse;
    }

    .change-services-display .ps-services-list-days-container {
        width: fit-content;
    }

    .change-services-display .no-assistants-container {
        margin-top: 0;
    }

    .change-services-display .my-requests-container {
        width: 60%;
        margin: 2vh 0 0 auto;
    }

}

@media(min-width:1200px) {
    .my-requests-container {
        width: 70%;
    }
}

.usertype-main-container {
    & .nav-bar {
        margin-inline: 12px;
        width: auto;
    }
}
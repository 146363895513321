.profile-container {
  width: 100%;
  background-color: transparent;
}

.menu-navbar-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.options-profile-container {
  justify-content: center;
  align-content: center;
  height: 35vh;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 90%;
  margin: auto;
  justify-items: center;
}

.sp-profile-container .options-profile-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 60vh;
  overflow-y: scroll;
  width: 90%;
  margin: auto;
  justify-items: center;
}

.sp-profile-container .containerMenuButton {
  width: 30%;
}

@media (min-width: 768px) {
  .sp-profile-container .menu-desktop-container {
    border-radius: 0;
  }

  .appbar-profile {
    display: flex;
    flex-direction: row;
    height: 20vh;
  }

  .profile-header-container {
    display: none;
  }

  .profile-user-info {
    flex-direction: row;
  }

  .profile-user-data {
    align-self: center;
  }

  .sp-profile-container .addIconSelected,
  .sp-profile-container .addRounded {
    width: 100px;
    height: 100px;
  }

  .sp-profile-container .profile-photo {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }

  .sp-profile-container .options-profile-container {
    width: 80%;
    margin-top: 5vh;
    height: auto;
    overflow-y: hidden;
  }

  .containerMenuButton {
    margin-bottom: 6vh;
  }

  .menuRounded {
    min-width: 60px;
    height: 60px;
  }

  .menuButton {
    width: 80px !important;
    height: 80px !important;
  }

  .textMenuButton {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .data-type {
    font-size: 12px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-rol {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .sp-profile-container .options-profile-container {
    width: 50%;
    margin-top: 10vh;
  }

  .data-type {
    font-size: 14px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-rol {
    font-size: 16px;
  }
}

.profile-container {
  & .nav-bar {
    margin-inline: 12px;
    width: auto;
  }
}
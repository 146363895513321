.sound-recorder-wrapper {
  --general-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: var(--general-width);

  & .header-sound-recorder {
    display: flex;

    & .recording-text {
      font-size: 13px;

      &>kbd {
        border: 1px solid #652d52;
        border-radius: 4px;
        padding: 1px 3px;
        background-color: color-mix(in srgb, transparent 95%, #652d52);
        color: #652d52;
      }
    }

    & .close-sound-recorder-btn {
      margin-inline: auto 0px;

      & .ant-btn-icon {
        display: flex;
      }
    }
  }

  & .sound-recorder {
    height: 100%;
    width: var(--general-width);
  }

  & .media-control-wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;

    &>* {
      box-shadow: none;
    }
  }

  & .preview-recording-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
    border-radius: 6px;
    background-color: #f1f3f4;
    padding-right: 12px;
    width: 100%;
    box-sizing: border-box;

    & audio {
      max-width: 100%;
    }

    & .clear-recording-btn {}
  }
}
.header-container {
    display: flex;
    position: sticky;
    top: 0px;
    align-items: center;
    z-index: 2;
    gap: 12px;
    justify-content: space-between;
    background-color: #fff;

    &>h1 {
      color: #652d52;
    }

    & .close-btn-floating-panel {
      align-self: flex-start;
      box-shadow: none;
      padding: 6px;
      border-radius: 50%;

      & .ant-btn-icon {
        display: flex;

        & svg {
          height: 24px;
          width: 24px;
          color: #fff;
        }
      }
    }
  }

.steps-container-new-unexpected{
    margin: 4px 4px 0px 4px;
}
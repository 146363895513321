.floating-material {
  .adm-floating-panel-header {
    display: flex;
    background: #652d52;
    border-bottom: 1px solid #e8e8e8;

    .adm-floating-panel-title {
      font-size: 16px;
      font-weight: 500;
    }

    .adm-floating-panel-close {
      cursor: pointer;
      font-size: 16px;
      color: #000;
    }
  }
}

.order-additional-material {
  display: grid;
  grid-template-rows: auto 1fr;
  box-sizing: border-box;
  padding: 12px;
  height: 100%;

  & h1 {
    margin: 0px;
  }

  .to-add-button {
    height: 60px;
  }

  & .header-container {
    display: flex;
    position: sticky;
    top: 0px;
    align-items: center;
    z-index: 2;
    gap: 12px;
    justify-content: space-between;
    background-color: #fff;

    &>h1 {
      color: #652d52;
    }

    & .close-btn-floating-panel {
      align-self: flex-start;
      box-shadow: none;
      padding: 6px;
      border-radius: 50%;

      & .ant-btn-icon {
        display: flex;

        & svg {
          height: 24px;
          width: 24px;
          color: #fff;
        }
      }
    }
  }

  & h1 {
    font-size: 24px;
  }

  & .material-form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    justify-content: unset;

    & .material-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow-y: auto;
      scrollbar-width: thin;
      margin-block: 18px 12px;
      position: relative;
      height: 100%;

      &:has(.ant-empty) {
        flex: 1;
        align-items: center;
        justify-content: center;
      }

      & .material-list-item {
        border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
        padding: 6px;
        border-radius: 12px;
        position: relative;

        & .remove-item-btn {
          box-shadow: none;
          position: absolute;
          top: 4px;
          right: 4px;
          z-index: 1;
        }

        & .material-item-wrapper {
          overflow: hidden;
          display: flex;
          flex-direction: column;

          & .material-select {
            height: 100%;
            width: 100% !important;

            &:has(.select-option-name) {
              & .ant-select-selector {
                border-color: transparent;
              }

              & .ant-select-arrow {
                display: none;
              }
            }

            & .select-option-wrapper {
              display: flex;
              gap: 6px;
              align-items: center;
              padding-block: 8px;

              & .material-image {
                border-radius: 6px;
                border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
                display: block;
                height: fit-content;
                z-index: 1;
              }

              & .select-option-name {
                text-transform: lowercase;
                display: block;
                white-space: wrap;
                line-height: normal;
                font-size: 18px;
                font-weight: 500;

                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }

          & .quantity-input {
            width: 100%;
          }
        }
      }

      & .ant-form-item:has(.why-select) {
        position: sticky;
        bottom: 0px;
        z-index: 1;
        border: 1px solid color-mix(in srgb, transparent 70%, #fd941b);
        padding: 24px 6px;
        border-radius: 12px;
        margin-block: auto 0px;
        background-color: color-mix(in srgb, #fff 93%, #fd941b);

        & .why-select {
          width: 100%;
        }
      }

      &>* {
        background-color: color-mix(in srgb, #fff 95%, #652d52);
      }
    }

    & .form-actions-section {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-block: auto 0px;

      & .to-add-button {}

      & .order-submit-btn {
        box-shadow: none;
        padding-block: 24px;
      }
    }
  }
}

.add-confirm-modal {
  height: 50dvh;
  overflow-y: scroll;

  .material-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
    border-radius: 12px;
    margin-bottom: 12px;
  }

  .material-detail {
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

}


.ant-select-item-option-content {
  white-space: wrap;

  & .select-option-wrapper {
    display: flex;
    gap: 6px;
    align-items: center;
    padding-block: 8px;

    & .material-image {
      border-radius: 6px;
      border: 1px solid color-mix(in srgb, transparent 80%, #652d52);
      display: block;
      height: fit-content;
      z-index: 1;
      object-fit: cover;
    }

    & .select-option-name {
      text-transform: lowercase;
      display: block;
      white-space: wrap;
      line-height: normal;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
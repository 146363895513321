@import "../../../style-app/predefined.css";
.home-container {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: transparent;
}

.options-items-container {
  height: 100%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.options-menu-container {
  padding: 7% 5% 0 5%;
  display: grid;
  width: 90%;
  /*height: var(--vh);*/
  grid-template-columns: auto auto;
  align-items: flex-start;
  overflow-y: scroll;
  /*grid-row-gap: 4%;*/
  height: calc(100% - 17vh - 55px);
}

.menu-navbar-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  /*height: 45px;*/
  height: 50px;
}

@media (min-width: 768px) {
  .home-container {
    height: calc(100% - 10vh);
  }

  .home-container .options-menu-container {
    width: 80%;
    margin: 5vh auto 0 auto;
  }

  .appbar-no-background .home-container .appbar {
    background-color: white;
    color: #652d52;
    display: flex !important;
    height: 10vh;
    box-shadow: 0 4px 2px 1px #f8f7f8;
    border-radius: 0;
    padding-bottom: 5px;
  }

  .options-menu-container {
    padding: 0;
    width: 100%;
    grid-template-columns: auto auto auto auto;
    height: fit-content;
    overflow-y: hidden;
  }

  .menu-navbar-container {
    display: none;
  }
}

@media (min-width: 1200px) {
  .home-container .options-menu-container {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  .home-container .options-menu-container {
    width: 70%;
  }
}

/* From here start the new home styles*/

.main-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

.section-home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 30px;
  overflow: scroll;
  height: fit-content;
}

.adm-tab-bar-item:nth-child(2) {
  position: fixed;
  bottom: 66px;
  left: -8px;
}

.section-home article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  min-width: 160px;
  height: 160px;
  padding: 20px;
  border-radius: 12px;
  box-sizing: border-box;
  color: #642c53;
}

.inside-menu {
  font-size: 14px;
  color: #642c53;
}

.icon-menu {
  margin-top: -34px;
}

article:not(#user-info):not(#progress):hover {
  scale: 1.04;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

#user-info {
  background-color: #f8f7f8;
  grid-column: span 2;
  grid-row: span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100px;
  align-self: flex-end;

  & .adm-dropdown-item-title {
    padding: 0;
    margin-top: -40px;
  }

  & .adm-dropdown-item-title-text {
    color: #642c53;
    font-weight: 600;
    font-size: 14px;
  }

  & h1 {
    margin: 0;
  }

  & .icon-menu {
    font-size: 70px;
    cursor: pointer;
    margin-top: -50px;
  }
}

#progress {
  background-color: #642c53;
  color: white;
  grid-column: span 2;
  grid-row: span 1;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 20px;

  & .ant-card-wallet {
    width: 300px;
    border-radius: 10px;
    font-size: 12px;
  }

  & .pay-card-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 100%;

    & p {
      font-size: 16px !important;
      color: #642c53 !important;
    }

    & .pay-card-inside {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    & .pay-card-inside-2 {
      display: flex;
      gap: 20px;
      width: 100%;

      & aside {
        width: 50%;
      }
    }
  }

  & .progress-icons-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div:last-child {
    & .progress-bar {
      width: 300px !important;
      height: 20px;
    }
    & p {
      color: #fd8c14;
      font-weight: 600;
      font-size: 30px;
    }
  }

  & .progress-title-span {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & p {
      font-size: 18px;
      font-weight: 700;
      color: #fd8c14;
    }
  }
}

#comercial {
  background-color: #fd8c14e1;
  grid-column: span 1;
  grid-row: span 1;
  & h2 {
    text-align: start;
  }
}

#design {
  background-color: #f9bacf;
  grid-column: span 1;
  grid-row: span 1;
  & h2 {
    text-align: start;
  }
}

#planeation {
  background-color: #d0c4e8;
  grid-column: span 1;
  grid-row: span 1;
  & h2 {
    text-align: start;
  }
}

#operations {
  background-color: #cfd8dd;
  grid-column: span 1;
  grid-row: span 1;
  & h2 {
    text-align: start;
  }
}

#work-plan {
  background-color: #fd8c14e1;
  grid-column: span 1;
  grid-row: span 1;
  & h2 {
    text-align: start;
  }
}

.tab-bar-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;

  & .home-icon-bar {
    background-color: white;
    color: #642c53;
    border: solid 1px #642c53;
    padding: 20px;
    border-radius: 100%;
  }

  & .new-nav-bar {
    background-color: #642c53;
    padding-block: 10px;
    border-radius: 12px;
    margin-bottom: 20px;
    width: 90%;

    & .adm-tab-bar-item {
      color: white;
      width: 100%;
    }
  }
}

.new-tab-bar-logo {
  display: none;
}

.new-nav-bar .aria-icon.active-tab .adm-tab-bar-item-title {
  color: #fd992f;
}

.avatar-phase {
  --size: 70px;
  align-self: flex-end;
}

#work-plan .avatar-phase {
  --size: 85px;
}

.progress-container {
  margin: 0;

  & .progress-bar-container {
    width: 300px;
  }

  & p {
    margin-top: -20px;
    display: none;
  }
}

@media (min-width: 768px) {
  .main-home {
    gap: 4rem;
  }

  .section-home {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin-top: 60px;
    width: 80%;
    max-width: 900px;
  }

  .section-home article {
    height: 220px;
  }

  .new-nav-bar {
    width: 98vw;
    height: fit-content;
    top: 0;
    padding: 20px;
    border-radius: 0 0 20px 20px !important;
    display: flex;
    justify-content: end;

    & .adm-tab-bar-wrap {
      width: 350px;
    }
  }

  #user-info {
    & .icon-menu {
      font-size: 40px;
      margin-top: -36px;
    }
  }

  #progress {
    & .ant-card-wallet {
      width: 400px;
      font-size: 14px;
    }
  }

  #comercial {
    & h2 {
      text-align: start;
      width: 150px;
    }
  }

  & .progress-title-span {
    & p {
      display: none;
    }
  }

  .new-tab-bar-logo {
    display: block;
    position: absolute;
    top: 15px;
    left: 40px;
    z-index: 1000;
  }

  .avatar-phase {
    --size: 110px;
  }

  #work-plan {
    & .avatar-phase {
      --size: 195px;
      scale: 0.7;
    }
  }

  .progress-container {
    display: flex;
    justify-content: space-between;
    & p {
      margin-top: -4px;
      display: block;
    }
  }
}

.baseBtn {
  border-radius: 10px;
  border-style: solid;
  border-width: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  width: 80%;
  max-width: 300px;
  cursor: pointer;
}

.baseBtn:hover {
  opacity: 50%;
}

.baseBtn:focus {
  outline: none;
}

.primary:focus {
  background-color: #fd941b;
  font-weight: bold;
  font-size: 16px;
}

.primary {
  background-color: #fd941b;
  width: 100%;
  min-width: 100px;
  height: 40px;
  /*color: #361a26;*/

  min-height: 25px;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  
  color: white
}

.iconBtn {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0 20px;
  align-content: center;
}

.iconText {
  justify-content: center;
  align-items: center;
  display: flex;
}

.fowardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  /*filter: invert(8%) sepia(12%) saturate(5781%) hue-rotate(290deg) brightness(87%) contrast(88%);*/
  filter: invert(100%);
}

.iconBtn:focus {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0 15px;
  align-content: center;
}

.iconText:focus {
  justify-content: center;
  align-items: center;
  display: flex;
}

.fowardIcon:focus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.categoryRounded {
  border-radius: 50%;
  min-width: 45px;
  height: 45px;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 5px;
}

.selectedCategoryRounded {
  background-color: #fd941b;
}

.selectedNavbarButton {
  background-color: #fd941b;
}

.selectedCategoryIcon {
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%) !important;
}

.menuRounded {
  border-radius: 50%;
  min-width: 45px;
  height: 45px;
  background-color: #652d52;
  color: white;
  display: flex;
  justify-content: center;
  margin: 1vh;
  align-items: center;
}


.addRounded {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  width: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreRounded {
  border-radius: 50%;
  min-width: 35px;
  height: 56px;
  width: 56px;
  background-color: #652d52;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -15%;
  align-items: center;
}

.more-rounded-portal-show{
  border-radius: 50%;
  min-width: 35px;
  height: 56px;
  width: 56px;
  background-color: #652d52;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 69px;
  right: 7px;
}

.menuIcon {
  width: 25px;
  height: 25px;
  filter: invert(100%);
}

.textMenuButton {
  color: #652d52;
  font-size: 11px;
  text-align: center;
  width: 100%;
}

.menuButton {
  width: 60px !important;
  height: 60px !important;
  text-align: center;
}

.containerMenuButton {
  /*width: 30%;*/
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
}

.categoryIcon {
  width: 25px;
  height: 25px;
  filter: invert(80%);
}

.textCategoryButton {
  color: #cccccc;
  font-size: 11px;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.selectedTextCategoryButton {
  color: #361a26;
}

.containerCategoryButton {
  width: 55px;
  min-width: 55px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.addIcon {
  width: 27px;
  height: 27px;
  filter: invert(90%);
}

.addIconSelected {
  width: 65px;
  height: 65px;
  filter: invert(0);
  border-radius: 50%;
}

.moreIcon {
  width: 20px;
  height: 20px;
  filter: invert(100%);
}

.whatsapp-btn-icon{
  width: 30px;
    height: 30px;
    filter: invert(100%);
}

.navbarRounded {
  border: none;
  min-width: 24px;
  height: 24px;
  background-color: white;
}

.selectedNavbarRounded {
  background-color: #fd941b;
}

.containerNavbarButton {
  width: 100%;
  min-width: 24px;
  height: 100%;
  line-height: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textNavbarButton {
  color: #361a26;
  font-size: 10px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.navbarIcon {
  width: 23px;
  height: 23px;
  filter: invert(18%) sepia(54%) saturate(714%) hue-rotate(268deg) brightness(98%) contrast(88%);
}

.popupBase {
  width: 70%;
  min-width: 50px;
  height: 45px;
  font-size: 14px;
}

.popupPurple {
  background-color: #652d52;
  color: white;
}

.btnYellow {
  background-color: #fd941b;
  /*color: #652d52;*/
  color: white;
  width: 90%;
}

.btnPurple {
  background-color: #652D52;
  color: white;
  width: 90%;
}

.btnGrey {
  background-color: #F0EAEE;
  color: #652D52;
  width: 90%;
  border: 1px solid #652D52;
}

.popupGrey {
  color: #652d52;
  background-color: #cccccc;
  border: 1px solid #652d52;
}

.containerButton {
  background-color: #fd941b;
  min-width: 30px;
  height: 20px;
  font-size: 10px;
  color: #361a26;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnVisible {
  visibility: visible;
}

.btnHidden {
  visibility: hidden;
}

.profileButton {
  background-color: #652d52;
  color: white;
  width: 25%;
  margin: 0 2.5% 0 2.5%;
  border-radius: 0;
  align-self: self-start;
}

.data-info {
  font-weight: bold;
  font-size: 35px;
}

.data-type {
  font-size: 10px;
  font-weight: normal;
}

.addSquareIcon {
  height: 52px;
}

.addSquare {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  width: 65px;
  background-color: transparent;
  border-radius: 10px;
  border: 2px dashed #4a2433;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tob-container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.tob-icon-container{
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tob-icon{
  min-width:20px;
  width: 30%;
  max-height: 30px;   
  filter:invert(80%);
}

.tob-text{
    color:#cccccc;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-right: 4px;
    line-height: 1;
    width: 60%;
}

.tob-buttons-container{
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 20%;
}

.tob-btn{
  font-size: 12px;
  text-align: center;
  color: #C4C4C4;
  font-weight: normal;
  background-color: transparent;
  padding: 8px;
  border-radius: 0px;

}

.tob-btn-left{
  border-right: 1px solid #C4C4C4;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tob-btn-right{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-sected{
  background-color: #fd941b;
  color: #361a26;
}

.containerCalendarDayBtn{
    width: 25%;
    min-width: 60px;
    height: 20vw;
    margin-left:2vw;
    padding: 1vh 0;
    max-height: 70px;
    max-width: 70px;
}

.calendar-item-btn{
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 3.5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  font-weight: 300;
  font-size: 16px;
  color: #652D52;
}

.selected-calendar-item-btn{
  background-color: #652D52;
  color: white;
}


.base-option-button{
  width: fit-content;
  max-width: 100%;
  border-radius: 5px;
  padding: 1vh 1vw;
  margin: .5vh .5vh;
  font-size: 12px;
  min-height: 40px;
}

.option-button{ 
  background: #FFFFFF;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  color: #CBCBCB;
}

.selected-option-button{
  background: #FD941B;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  color: #3E1E2B;
}

.assistants-button{
  color: white;
  background: #FD941B;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 14px;
}

.ab-icon{
  filter: invert(100%);
  width: 80%;
}

.ab-icon-container{
  display: flex;
}

.ab-text{
  font-weight: 600;
}

.assistants-button-container{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 1.5vh 0;
  min-height: 25px;
}

.new-primary-button-container{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.5vh 0;
  min-height: 25px;
}

.new-primary-btn{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.7vh 0;
  min-height: 25px;
  border-radius: 10px;
  font-weight: bold;
font-size: 12px;
}

.new-icon-button-container{
  display: flex;
  margin-right: 10px;
}

.purplePrimaryBtn{
  background-color: #652D52;
}

.show-menu-portal{
  background-color: transparent;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 4;
}

.hide-menu-portal{
  visibility: hidden;
  width: 0;
  height: 0;
}


.whatsappButton{
  min-width: 35px;
  height: 56px;
  width: 56px;
  display: none;
  justify-content: center;
  position: absolute;
  top: -15%;
  background-color: #7AD06D;
  box-shadow: 0px 4px 8.79px #BAE3B4;
  border-radius: 10px;
  align-items: center;
}

.seguimientoButton{
  min-width: 35px;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -15%;
  background-color: #FD941B;
  box-shadow: 0px 0px 8.78646px #FF8F1C;
  border-radius: 10px;
  align-items: center;
  margin-right: 20px;
  margin-top: 40px;
}

.seguimiento-button-portal-show{
  min-width: 35px;
  height: 56px;
  width: 56px;
  background-color: #FD941B;
  box-shadow: 0px 0px 8.78646px #FF8F1C;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 28px;
  right: 8px;
  align-items: center;
  margin-right: 20px;
}

.new-goal-button-style{
  font-size: 12px;
    border-radius: 10px;
    color: white;
    width: 100%;
}

.btn-yellow{
  background: #FD941B;
  box-shadow: 0px 3.5px 32px rgba(0, 0, 0, 0.1);
  color: white;
  width: 100%;
  border-radius: 10px;
}

.payu-button{
  padding: 1.5vh 0;
  min-height: 25px;
  width: 90%;
  margin: 0 5% 2vh 5%;
}

.completed-pay-btn{
  width: 50%;
  margin: 0 25% 2vh 25%;
  display: none;
}

.assistants-btn-disable{
  display: none;
}

form{
  display: flex;
  justify-content: center;
}

.menu-desktop-main-container .whatsappButton{
  visibility: hidden;
}

@media(min-width:768px){
  .menu-desktop-main-container .whatsappButton{
    visibility: visible;
    top:15vh;
    right: 20%;
  }

  .menu-desktop-main-container .seguimientoButton, .seguimiento-button-portal-show {
    top:15vh;
    right: 19.5%;
  }

  .menu-desktop-main-container .seguimiento-button-portal-show {
    top:85vh;
    right: 26%;
  }

  .menu-desktop-main-container .container-values-seguimiento {
    bottom: 15vh ;
    right: calc(26% - 180px);
  }
}

@media(min-width:1200px){


  .menu-desktop-main-container .seguimientoButton, .seguimiento-button-portal-show {
    right: 22.5%;
    top: 3vh;
  }

  .menu-desktop-main-container .seguimiento-button-portal-show {
    right: 34%;
  }

  .menu-desktop-main-container .container-values-seguimiento {
    right: calc(32% - 180px);
  }

  .menu-desktop-main-container .whatsappButton{
    right: 22%;
    top:3vh;
  }

}

@media(min-width:1400px){


  .menu-desktop-main-container .seguimientoButton{
    right: 24.5%;
    top: 15vh;
  }

  .menu-desktop-main-container .seguimiento-button-portal-show {
    right: 36%;
    /*top: 15vh;*/
    top:85vh
  }

  .menu-desktop-main-container .container-values-seguimiento {
    right: calc(34% - 180px);
  }

  .menu-desktop-main-container .whatsappButton{
    right: 24%;
    top:15vh;
  }
}

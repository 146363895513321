.bitacle-chat-empty {
  display: flex;
  flex-direction: column;
  border: var(--border);
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px;

  & .ant-empty-description {
    color: color-mix(in srgb, transparent 60%, #652d52);
  }
}

.bitacle-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* Estilos para el componente de tabs */
.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 20px;
  margin: 0 10px;
  border: none;
  background: transparent;
  color: #ffff;
  font-size: 18px;
  font-weight: 5500;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: transparent;
  transition: all 0.2s ease;
}

.tab.active {
  margin-top: 2px;
  background-color: white;
  color : #652d52;
  height: 40px;
  border-radius: 6px;
  margin-bottom: 2px;
}

.tab:hover {
  color: #;
}


.tab-content {
  text-align: left;
}

.tabs-header{
  display:flex;
}

.tabs-container {
  width: 100%;
}

.tab-content {
  width: 100%;
  margin-top: 20px;
}

.tab-content > div {
  width: inherit;
}


.new-entry-appbar-container{
    background-color: #652D52;
    height: 15vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ne-appbar-small-text{
    font-weight: 500;
    font-size: 14px;
}

.ne-appbar-big-text{
    font-weight: bold;
    font-size: 18px;
}

.new-entry-container{
    height: calc(100% + 50px);
}

.ps-new-entry-title{
    font-weight: bold;
    font-size: 18px;
    margin: 2vh auto 0vh auto;
}

.title-bottom-margin{
    margin-bottom: 3vh;
}

.new-entry-content{
    height: calc(100% - 15vh);
    /*overflow-y: scroll;*/
}

.new-entry-dropdown-container{
    width: 90%;
    margin: auto;
    max-height: 50px;
}

.new-entry-dropdown{
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 45px;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    align-items: center;
    min-height: 30px;
    width: 100%;
}

.time-entry-container{
    display: flex;
    width: 90%;
    margin: 0 auto 5vh auto;
}

.new-time-entry{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.time-entry-text{
    font-weight: 600;
    font-size: 12px;
    color: #4A2433;
    margin-bottom: 1vh;
}

.select-work-container{
    width: 90%;
    margin: auto;
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: column;
    justify-content: space-between;
}

.base-input-imprevisto{
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 45px;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    align-items: center;
    min-height: 30px;
    width: 100%;
    padding-left: 10%;
}

.descripcion-container-imprevisto{
    margin-bottom: 2vh;
}

.new-entry-button{
    width: 100%;
}

.imprevisto-container{
    font-weight: bold;
    font-size: 18px;
    margin: 2vh auto 3vh auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4A2433;
}

.imprevisto-close-icon{
    width: 80%;
    height: 70%;
    filter: invert(11%) sepia(24%) saturate(2292%) hue-rotate(292deg) brightness(100%) contrast(84%);
}

.update-disable{
    background-color: #cccc;
}

.finished-item{
    margin-top: 1vh;
}

@media(min-width:768px){

    .new-entry-appbar-container{
        height: 10vh;
    }

    .new-entry-content{
        height: calc(100% - 17vh);
        overflow-y: scroll;
    }
}
.new-entry-content {
    height: calc(100% - 17vh);
    overflow-y: scroll;
}


.categoria-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.new-service-text {
    width: 90%;
    margin: 1vh auto 1vh auto;
    display: flex;
    justify-content: left;
    align-items: center;
    align-content: center;
    color: #ff8d00;
    font-size: 16px;
    font-weight: 600;
}

.new-entry-dropdown-container {
    width: 90%;
    margin: auto;
    max-height: 50px;
    margin-bottom: 10px;
}

.new-entry-dropdown {
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 45px;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    align-items: center;
    min-height: 30px;
    width: 100%;
}

.baseDropdown {
    background-color: white;
    border: none;
    outline: none;
    height: 45px;
    width: 100%;
    border-radius: 12px;
    color: #ABAAAA;
    font-size: 13px;
    font-weight: normal;
    min-width: 43px;
    cursor: pointer;
}

.desktop-container {
    height: 100%;
}

.new-service-text {
    width: 90%;
    margin: 1vh auto 1vh auto;
    display: flex;
    justify-content: left;
    align-items: center;
    align-content: center;
    color: #ff8d00;
    font-size: 16px;
    font-weight: 600;
}

.time-entry-container {
    display: flex;
    width: 90%;
    margin: 0 auto 5vh auto;
}
.new-service-text div {
    margin-right: 4px;
}

.baseTextarea {
    background-color: white;
    width: 80%;
    resize: none;
    max-height: 100px;
    min-width: 100px;
    height: 80%;
    align-items: center;
    min-height: 30px;
    outline: none;
    border: none;
    border-top-right-radius: 13px;
    padding: 3% 0 0 1%;
    font-size: 14px;
    color: #4A2433;
}

.descripcion-input {
    width: 87%;
    margin: auto;
    display: flex;
    justify-content: left;
    min-width: 100px;
    height: 100px;
    background-color: white;
    box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    min-height: 30px;
    padding-left: 3%;
    align-items: flex-end;
}

.add-foto-container {
    margin-right: 5px;
}
.dano-foto {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}
.foto-source-container {
    position: relative;
    margin-right: 5px;
    margin-bottom: 7px;
    cursor: pointer;
}
.add-foto-container, .foto-source-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.deletePhoto {
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 78%;
    top: -10%;
}
.add-material-container {
    width: 90%;
    margin: 4vh auto;
    display: flex;
    font-size: 14px;
    color: #FD941B;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}

.name-roler{
    display: flex;
    justify-content: left;
    height: 45px;
    align-items: center;
    min-height: 30px;
    width: 100%;
}

.name-input-roler{
    margin-left: 2vh;
    border-radius: 13px;
    width: 90%;
    padding-left: 5px;
}

.name-input-roler:focus{
    background-color: white;
}

.name-input-roler::placeholder{
    color: #ABAAAA;
    font-size: 13px;
    font-weight: normal;
    min-width: 43px;
    margin-left : 2vh;
}


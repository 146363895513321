@import "../../style-app//predefined.css";

.usertype-container {
  width: 100%;
  /*height: 80vh;*/
}

.appbar-container {
  margin-bottom: 3%;
}

.text-usertype {
  width: 90%;
  margin: 1vh auto 2vh auto;
  display: flex;
  justify-content: left;

  align-items: center;
  color: #4a2433;
  font-size: 16px;
  font-weight: bold;
}

#img-text-usertype {
  width: 80%;
  height: 70%;
  filter: invert(11%) sepia(24%) saturate(2292%) hue-rotate(292deg) brightness(100%) contrast(84%);
}

.register-botton-menu {
  display: flex;
  width: 90%;
  margin: 2vh auto 0 auto;
  margin-bottom: 100px;

  text-align: center;
  justify-content: center;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 12.5vh;
}

.options-usertype {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: auto auto;
  grid-row-gap: 1.5vh;
  align-items: flex-start;
}

.more-usertype {
  margin-top: 1.8vh;
}

.fields-container {
  width: 100%;
  margin: 0 auto 2vh auto;
  line-height: 0.5;
  display: flex;
  /*flex-direction: column;*/
}

.fields-container .acceso-cubierta-info {
  cursor: pointer;
}

.register-next-btn {
  width: 90%;
  margin: auto;
  position: fixed;
  top: 90%;
  left: 5%;
}

.order-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*height: var(--v-form-property);
  height: 100%;
  overflow-y: scroll;*/
}

.options-fields {
  padding-right: 6px;
  padding-left: 6px;
  /*height: 45vh;*/
}

.text-property {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.note-property {
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.register-encargado {
  font-size: 18px;
  color: #4a2433;
  width: 90%;
  margin: 4vh auto 2vh auto;
  color: #4a2433;
  font-weight: bold;
}

.additional-info-register {
  width: 80%;
  resize: none;
  min-width: 100px;
  height: 100px;
  box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  align-items: center;
  min-height: 30px;
  outline: none;
  border: none;
  padding: 3% 0 3% 20%;
}

.register-button {
  margin-top: 4vh;
  margin-bottom: 2.5vh;
  padding-bottom: 18px;
}

.button-new-personal {
  margin-top: 4vh;
}

.acceso-cubierta-info {
  color: #fd941b;
  font-weight: normal;
  font-size: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.interrogation {
  margin-left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: white;
  background-color: #fd941b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-new-personal {
  width: 90%;
  margin: 2vh auto 2vh auto;
  color: #fd941b;
  font-size: 12px;
}

.fields-title {
  display: flex;
  font-size: 16px;
  color: #4a2433;
  font-weight: bold;
  align-items: center;
  margin: 2vh 0;
}

.fields-title-icon {
  width: 75%;
  filter: invert(11%) sepia(24%) saturate(2292%) hue-rotate(292deg) brightness(100%) contrast(84%);
}

.name-property-label {
  font-weight: 600;
  font-size: 14px;
}

.valid-time-text {
  width: 100%;
  margin: 1vh auto 2vh auto;
  display: flex;
  justify-content: left;
  align-items: center;
  align-content: center;
  color: #4A2433;
  font-size: 12px;
  font-weight: bold;
}

.time-entry-container-client {
  display: flex;
  width: 100%;
  margin: 0 auto 3vh auto;
  justify-content: space-between;
}


.new-entry-dropdown {
  display: flex;
  justify-content: left;
  min-width: 100px;
  height: 45px !important;
  box-shadow: 0px 3.5px 13px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  align-items: center;
  min-height: 30px;
  width: 100% !important;
}

.pass-text {
  font-size: 12px;
  color: #fd941b;
  margin: 7px 0;
  line-height: 1.2;
}

@media(min-width:768px) {
  .options-usertype {
    grid-template-columns: auto auto auto;
    grid-row-gap: 4vh;
    margin: 5vh auto 0 auto;
  }

  .more-usertype {
    margin: 4vh 0;
  }

  .appbar-no-background .appbar-container {
    display: block !important;
  }

  .appbar-no-background .appbar-container .appbar {
    background-color: white;
    color: #652D52;
    display: flex !important;
    height: 10vh;
    box-shadow: 0 4px 2px 1px #F8F7F8;
    border-radius: 0;
    padding-bottom: 5px;

  }

  .appbar-no-background .register-type-container {
    height: 50%
  }

}

@media (min-width: 992px) {
  .options-usertype {
    grid-template-columns: auto auto auto;
    grid-row-gap: 5vh;
    margin: 4vh auto 0 auto;
  }

  .more-usertype {
    margin: 5vh 0;
  }
}